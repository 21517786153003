import styled from "styled-components"
import {
  MEDIUM,
  LARGE,
  LANDSCAPE,
  MOBILE,
  TAB,
} from "../../constants/breakpoints"
import {
  PROP_FONT_PRIMARY,
  PROP_FONT_SECONDARY,
} from "../../constants/props"

// FEATURED STATION COMPONENTS

export const StationFeaturedWrapper = styled.div`
  display: flex;
`
export const StationFeaturedImageWrapper = styled.div`
  position: relative;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.box.station.featured.image.widthHeight}vw;
    height: ${MOBILE.box.station.featured.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.box.station.featured.image.widthHeight}vw;
    height: ${LANDSCAPE.box.station.featured.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.box.station.featured.image.widthHeight}px;
    height: ${TAB.box.station.featured.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.box.station.featured.image.widthHeight}px;
    height: ${MEDIUM.box.station.featured.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.box.station.featured.image.widthHeight}px;
    height: ${LARGE.box.station.featured.image.widthHeight}px;
  }
`
export const StationFeaturedRightTextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  letter-spacing: 1.5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-left: ${MOBILE.box.station.featured.contentGap}vw;
    width: ${MOBILE.contentWidth -
      MOBILE.box.station.featured.contentGap -
      MOBILE.box.station.featured.image.widthHeight}vw;
    padding-right: 3px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    margin-left: ${LANDSCAPE.box.station.featured.contentGap}vw;
    width: ${LANDSCAPE.contentWidth -
      LANDSCAPE.box.station.featured.contentGap -
      LANDSCAPE.box.station.featured.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-left: ${TAB.box.station.featured.contentGap}px;
    width: ${TAB.contentWidth -
      TAB.box.station.featured.contentGap -
      TAB.box.station.featured.image.widthHeight}px;
    padding-right: 10px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-left: ${MEDIUM.box.station.featured.contentGap}px;
    width: ${MEDIUM.contentWidth -
      MEDIUM.box.station.featured.contentGap -
      MEDIUM.box.station.featured.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    margin-left: ${LARGE.box.station.featured.contentGap}px;
    width: ${LARGE.contentWidth -
      LARGE.box.station.featured.contentGap -
      LARGE.box.station.featured.image.widthHeight}px;
  }
`

export const StationFeaturedTitle = styled.h3`
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  letter-spacing: 1.5px;
  font-size: ${MOBILE.box.station.featured.font.title}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.station.featured.font.title}px;
  }
`

export const StationFeaturedSingleLineText = styled.div`
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${MOBILE.box.station.featured.font.content}px;
  & div {
    font-family: ${PROP_FONT_PRIMARY};
    padding-right: 3px;
    margin-bottom: 3px;
    display: inline-block;
  }
  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: inline-block;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.station.featured.font.content}px;
    padding-right: 5px;
    margin-bottom: 5px;
  }
`
export const StationFeaturedMultiLineText = styled.span`
  margin: 0;
  font-size: ${MOBILE.box.station.featured.font.content}px;
  flex-grow: 1;

  & div {
    font-family: ${PROP_FONT_PRIMARY};
    margin-bottom: 3px;
    padding-right: 3px;
  }

  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: inline-block;
    }
  }

  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.station.featured.font.content}px;
    margin-bottom: 5px;
    padding-right: 5px;
  }
`

// STATION DETAILS COMPONENTS BELOW
export const StationDetailsWrapper = styled.div`
  display: flex;
`
export const StationDetailsImageWrapper = styled.div`
  width: 100%;
  position: relative;
  border-radius: ${MOBILE.box.station.details.image.borderRadius}px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.box.station.details.image.widthHeight}vw;
    height: ${MOBILE.box.station.details.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.box.station.details.image.widthHeight}vw;
    height: ${LANDSCAPE.box.station.details.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.box.station.details.image.widthHeight}px;
    height: ${TAB.box.station.details.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.box.station.details.image.widthHeight}px;
    height: ${MEDIUM.box.station.details.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.box.station.details.image.widthHeight}px;
    height: ${LARGE.box.station.details.image.widthHeight}px;
  }
`

export const StationDetailsRightTextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  letter-spacing: 1.5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-left: ${MOBILE.box.station.details.contentGap}vw;
    width: ${MOBILE.contentWidth -
      MOBILE.box.station.details.contentGap -
      MOBILE.box.station.details.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    margin-left: ${LANDSCAPE.box.station.details.contentGap}vw;
    width: ${LANDSCAPE.contentWidth -
      LANDSCAPE.box.station.details.contentGap -
      LANDSCAPE.box.station.details.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-left: ${TAB.box.station.details.contentGap}px;
    width: ${TAB.contentWidth -
      TAB.box.station.details.contentGap -
      TAB.box.station.details.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-left: ${MEDIUM.box.station.details.contentGap}px;
    width: ${MEDIUM.contentWidth -
      MEDIUM.box.station.details.contentGap -
      MEDIUM.box.station.details.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    margin-left: ${LARGE.box.station.details.contentGap}px;
    width: ${LARGE.contentWidth -
      LARGE.box.station.details.contentGap -
      LARGE.box.station.details.image.widthHeight}px;
  }
`

export const StationDetailsSingleLineText = styled.div`
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${MOBILE.box.station.details.font.content}px;
  & div {
    font-family: ${PROP_FONT_PRIMARY};
    padding-right: 3px;
    margin-bottom: 3px;
    display: inline-block;
  }
  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: inline-block;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.station.details.font.content}px;
    padding-right: 5px;
    margin-bottom: 5px;
  }
`

export const StationDetailsMultilineText = styled.span`
  margin: 0;
  font-size: ${MOBILE.box.station.details.font.content}px;

  & div {
    font-family: ${PROP_FONT_PRIMARY};
    margin-bottom: 3px;
  }

  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: block;
    }
  }

  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.station.details.font.content}px;
    margin-bottom: 5px;
  }
`

// Station grid components

export const StationAllWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.stations.grid.cardRow}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.stations.grid.cardRow}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.stations.grid.cardRow}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.stations.grid.cardRow}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.stations.grid.cardRow}px;
  }
`
export const StationGridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: ${MOBILE.stations.grid.paddingBottom}vh;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.stations.grid.cardWidth}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.stations.grid.cardWidth}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.stations.grid.cardWidth}px;
    padding-bottom: ${TAB.stations.grid.paddingBottom}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.stations.grid.cardWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.stations.grid.cardWidth}px;
  }
`
export const StationGridImageWrapper = styled.div`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.stations.grid.image.widthHeight}vw;
    height: ${MOBILE.stations.grid.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.stations.grid.image.widthHeight}vw;
    height: ${LANDSCAPE.stations.grid.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.stations.grid.image.widthHeight}px;
    height: ${TAB.stations.grid.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.stations.grid.image.widthHeight}px;
    height: ${MEDIUM.stations.grid.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.stations.grid.image.widthHeight}px;
    height: ${LARGE.stations.grid.image.widthHeight}px;
  }
`

export const StationGridSingleLineText = styled.div`
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${MOBILE.stations.grid.font.content}px;
  
  & label {
    font-family: ${PROP_FONT_PRIMARY};
    padding-right: 3px;
    margin-bottom: 3px;
    display: inline-block;
  }
  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: inline-block;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.stations.grid.font.content}px;
    & label {
      margin-bottom: 5px;
      padding-right: 5px;
    }
  }
`

export const StationGridTitle = styled.h3`
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 3px;
  overflow: hidden;
  font-size: ${MOBILE.stations.grid.font.title}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.stations.grid.font.title}px;
    margin-bottom: 5px;
  }
`

export const StationGridBottomTextSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 10px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.stations.grid.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.stations.grid.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.stations.grid.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.stations.grid.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.stations.grid.image.widthHeight}px;
  }
`

export const StationGridRow = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`
// slider components for station

export const StationSliderWrappper = styled.div`
  display: flex;
  flex-direction: column;
`
export const StationSliderImageWrapper = styled.div`
  position: relative;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.stations.slider.image.widthHeight}vw;
    height: ${MOBILE.stations.slider.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.stations.slider.image.widthHeight}vw;
    height: ${LANDSCAPE.stations.slider.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.stations.slider.image.widthHeight}px;
    height: ${TAB.stations.slider.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.stations.slider.image.widthHeight}px;
    height: ${MEDIUM.stations.slider.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.stations.slider.image.widthHeight}px;
    height: ${LARGE.stations.slider.image.widthHeight}px;
  }
`

export const StationSliderBottomTextSection = styled.div`
  margin-top: ${MOBILE.stations.slider.textMarginTop}vh;
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: ${TAB.stations.slider.textMarginTop}px;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.stations.slider.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.stations.slider.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.stations.slider.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.stations.slider.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.stations.slider.image.widthHeight}px;
  }
`

export const StationSliderTitle = styled.div`
  display: block;
  font-family: ${PROP_FONT_PRIMARY};
  font-size: ${MOBILE.stations.slider.font.title}px;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: left;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.stations.slider.font.title}px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    &:hover {
      text-decoration: none;
      transform: scale(${LANDSCAPE.linkHover.scale}) translateX(${LANDSCAPE.linkHover.translateX}px);
      transition: all ${LANDSCAPE.linkHover.transition}s;
      & h3 {
        color: ${LANDSCAPE.linkHover.color};
      }
    }
  }
`

export const StationSliderSingleLineText = styled.div`
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${MOBILE.stations.slider.font.content}px;
  
  & label {
    font-family: ${PROP_FONT_PRIMARY};
    padding-right: 3px;
    margin-bottom: 3px;
  }
  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: inline-block;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.stations.slider.font.content}px;
    & label {
      margin-bottom: 5px;
      padding-right: 5px;
    }
  }
`
export const StationSliderRow = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`
