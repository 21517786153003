export const MEDIUM = {
  minWidth: 1200,
  page: 1200,
  contentWidth: 750,
  websitePadding: 10,
  aboutPage: {
    paragraph: 16, // in px
    heading: 26, // in px
    imageSize: 180, // in px
  },
  layout: {
    paddingTop: 15, // in px
    videoPlayerControls: 14, // in px
    playPauseButton: 45, // in px
  },
  header: {
    height: 9, // in vh
    logoHeight: 8, // in vh
    subMenuFont: 0, // font of sub menu also its height in header
    bottomMargin: 0, // Bottom margin of header
  },
  search: {
    height: 30, // in vh
    borderRadius: 5,
    font: 15,
    result: {
      font: 16,
      top: 7, // in vh
    },
  },
  player: {
    topMargin: 40, // margin from header, this helps in aligning it to ads 
    controls: {
      bottom: {
        height: 75, // in px, height of bottom player controls
      }
    }
  },
  countrySlider: {
    heading: {
      paddingTop: 0,
      paddingBottom: 0,
      font: 26,
      subHeadingFont: 28,
      subTextFont: 14,
    },
    search: {
      width: 400, // in px
      marginBottom: 20,
    },
    flag: {
      large: {
        font: 22, // in px
        width: 250, // in px
        height: 145, // in px
        borderRadius: 20, // in px
      },
      small: {
        flagRow: 1190, // in px
        font: 16,
        padding: 25,
        width: 120, // in px,
        height: 72.5, // in px
        selectedWidthIncrease: 0, // should not be more than padding
        selectedHeightIncrease: 0, // should not be more than padding
        borderRadius: 10, // in px
      },
    },
  },
  box: {
    width: 900, // in px
    height: 380, // in px
    borderRadius: 40,
    anchorHeight: 24,
    carouselPaddingRight: 50, // in px
    marginBottom: 20, // gap between heading and image/content section
    iconSize: 20, // like and share button in px
    arrowIconWidth: 35,
    font: {
      mainHeading: 36, // Font size of top heading
      title: 24,
      content: 16,
    },
    station: {
      featured: {
        contentGap: 15, // in px
        textOffset: 200,
        image: {
          widthHeight: 180,
          borderRadius: 20,
        },
      },
      details: {
        contentGap: 30,
        textOffset: 200,
        font: {
          title: 16,
          content: 14,
        },
        image: {
          widthHeight: 180, // in px
          borderRadius: 20,
        },
      },
      grid: {
        image: {
          widthHeight: 200,
          borderRadius: 20,
        },
      },
    },
    artist: {
      featured: {
        contentGap: 30,
        textOffset: 200,
        image: {
          widthHeight: 180,
        },
      },
      details: {
        contentGap: 30,
        textOffset: 200,
        font: {
          title: 16,
          content: 14,
        },
        image: {
          widthHeight: 180, // in px
          borderRadius: 20,
        },
        cover: {
          width: 400,
          height: 300,
        },
      },
    },
    podcasts: {
      image: {
        widthHeight: 200,
        borderRadius: 20,
      },
      font: {
        title: 18,
        content: 14,
      },
      contentGap: 30, // gap between image and content
      textSectionWidth: 500, // For smooth sliding experience of text in gray box we need to give fix width
      textOffset: 200, // Read more text characters to allow
    },
    videos: {
      featured: {
        textOffset: 100,
      },
      playing: {
        width: 900,
        height: 420,
        floatingWidth: 500,
        floatingHeight: 500 * 0.56,
      },
      image: {
        width: 750,
        height: 300,
      },
    },
  },
  stations: {
    grid: {
      cardRow: 825,
      cardWidth: 275,
      image: {
        widthHeight: 200,
        borderRadius: 20,
      },
    },
    slider: {
      slides: 3,
      cardRow: 825, // in px
      textOffset: 80,
      image: {
        widthHeight: 200, // in vw
        paddingRight: 75,
        borderRadius: 20,
      },
      font: {
        title: 24,
        content: 18,
      },
    },
  },
  podcasts: {
    font: {
      title: 18,
      content: 14,
    },
    slider: {
      slides: 3,
      cardRow: 825, // in px
      textOffset: 80,
      image: {
        widthHeight: 200, // in vw
        paddingRight: 75,
        borderRadius: 20,
      },
      font: {
        title: 24,
        content: 18,
      },
    },
    grid: {
      imageWidthHeight: 200, // in px
    },
    list: {
      contentGap: 20, // Space between image card and content,
      shareLikeIconSize: 20,
      textOffset: 100, // read more text offset
      font: {
        mainHeading: 30, // station name and sort heading
        title: 20, // podcast title H3
        date: 15, // podcast date span
      },
      image: {
        borderRadius: 30,
        widthHeight: 250,
      },
    },
  },
  videos: {
    slider: {
      slides: 2,
      textOffset: 120,
      cardRow: 786, // in px
      image: {
        width: 357,
        height: 357 * 0.56,
      },
      font: {
        title: 18,
        content: 14,
      },
    },
    grid: {
      cardRow: 786,
      cardWidth: 393,
      image: {
        width: 357,
        height: 357 * 0.56,
      },
      font: {
        title: 14,
        content: 12,
      },
    },
  },
  presenters: {
    slider: {
      slides: 4,
      cardRow: 800, // in px
      cardWidth: 150, // in px
      image: {
        widthHeight: 125,
      },
      font: {
        title: 18,
        content: 14,
      },
    },
  },
  artists: {
    grid: {
      contentGap: 30,
      textOffset: 200,
      image: {
        widthHeight: 200, // in px
      },
      font: {
        title: 18,
        content: 14,
      },
    },
  },
  publicPages: {
    signin: {
      width: 600,
      font: {
        heading: 26,
      },
    },
    signup: {
      width: 1200,
      formWidth: 520,
      font: {
        heading: 26,
      },
    },
    HeadingBottomMargin: 15,
  },
  ads: {
    sideAdsWidth: 150,
    bottomAdsWidth: 600,
    bottomAdsHeight: 600 * 0.15625,
  },
  userdata: {
    formWidth: 450,
  },
}
