// DEFAULT
export const COLOR_DEFAULT_TEXT_LIGHT = "#EEEEEE"
export const COLOR_DEFAULT_TEXT_WHITE = "#FFFFFF"
export const COLOR_DEFAULT_TEXT_DARK = "#231F20"
export const COLOR_DEFAULT_TEXT_DISABLED = "#C8C7C7"
export const COLOR_DEFAULT_BG_LIGHT = "#EEEEEE"
export const COLOR_DEFAULT_BG_DARK = "#231F20"
export const COLOR_DEFAULT_FLAT_BUTTON = "#AFAFAF"
export const COLOR_LOGO_TEXT = "#3F3E3A"
export const COLOR_HEADER_BG = "#001B32"
export const COLOR_MOBILE_HEADER_BG = "#EDEDED"
export const COLOR_MENU_BG = "#F8F8F800"
export const COLOR_PLAYER_BG = "#EEEEEE"
export const COLOR_OUTLINE = "#231F20"
export const COLOR_OUTLINE_LIGHT = "#FFFFFF"
export const COLOR_MENU_TEXT = "#DEDEDD"
export const COLOR_THEME_COLOR = "#00A6A9"
export const COLOR_THEME_COLOR_RGB = "rgb(0,166,169)"
export const COLOR_SEARCH_INPUT_TEXT = "#EEEEEE"
export const COLOR_SEARCH_INPUT_PLACEHOLDER_TEXT = "#AFAFAF"
export const COLOR_SEARCH_INPUT_MOBILE_BORDER = "#444444"
export const COLOR_SIGNINUP_FORM_INPUT_BORDER = "#EEEEEE"
export const COLOR_ERROR_TEXT = "#EB3626"
export const COLOR_SET_FAVORITE_FALSE = "#DFDFDF"
export const COLOR_SET_FAVORITE_TRUE = "#FF4B5D"

// LANDING PAGE
export const COLOR_FEATURED_TEXT = "#3F3E3A"
export const COLOR_LIST_CARD_TEXT = "#231F20"

// AUDIO-COUNTRIES
// AUDIO-STATIONS
// AUDIO-PODCASTS

// VIDEO-COUNTRIES
// VIDEO-STATIONS
