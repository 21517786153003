
import React, { useState, useContext, useEffect, useRef } from "react"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../../constants/breakpoints"
import {
  PROP_FONT_PRIMARY
} from "../../../constants/props"
import styled from "styled-components"
import { AppContext } from "../../../contexts/AppContext"

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  font-family: ${PROP_FONT_PRIMARY};
  font-size: 1.8rem;
  letter-spacing: 1.5px;
  line-height: 2rem;
  margin-left: 1vw;
  /* border: 1px solid red; */
`

const AvatarImg = styled.img`
  border-radius: 50%;
  object-fit: cover;
  object-position: center top;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: 18vw;
    height: 18vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: 8vw;
    height: 8vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: 8vw;
    height: 8vw;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: 4vw;
    height: 4vw;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: 4vw;
    height: 4vw;
  }
`

const UserName = styled.div`
  font-size: 22px;
  padding-left: 5px;
  color: #dededd;
  letter-spacing: 2px;
  line-height: 1.5;
`
const NotUserName = styled.p`
  font-size: 20px;
  padding-left: 10px;
  color: #dededd;
`
const UserEmail = styled.div`
  font-size: 14px;
  color: #dededd;
  margin: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 5px;
`

function useOutsideAlerter(
  ref,
  localUserState,
  setlocalUserState,
  onUserDropdown
) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      onUserDropdown(!localUserState.showUserDropdown)
      setlocalUserState({
        ...localUserState,
        showUserDropdown: !localUserState.showUserDropdown,
      })
    }
  }

  useEffect(() => {
    // Bind the event listener
    if (localUserState.showUserDropdown) {
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }
  })
}

const UserSection = props => {
  const {
    userState,
    resetRequestSignin,
  } = useContext(AppContext)

  const [localUserState, setlocalUserState] = useState({
    userData: { ...props },
    showUserDropdown: false,
    formChoice: "signin",
    userFirstName: "",
    userLastName: "",
    userEmail: "",
  })

  const wrapperRef = useRef(null)
  useOutsideAlerter(
    wrapperRef,
    localUserState,
    setlocalUserState,
    props.onUserDropdown
  )

  const onRequestSignin = () => {
    resetRequestSignin()
    props.onUserDropdown(true)
    setlocalUserState({
      ...localUserState,
      showUserDropdown: true,
      formChoice: "signin",
      userEmail: "",
    })
  }

  useEffect(() => {
    userState.requestSignin && onRequestSignin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState])

  return (
    <UserInfo ref={wrapperRef}>
      <AvatarImg
        src={"" || (userState.isLoggedIn ? "/images/loggedUser.png" : "/images/guestUser.png")}
        alt="profile-pic"
      />
      {userState.isLoggedIn ? (
        <div style={{ display: "flex", flexDirection: "column", width:"76%" }}>
          <UserName title={userState.firstname}>
            <b>{userState.firstname}</b>
          </UserName>
          <UserEmail title={userState.email}>{userState.email}</UserEmail>
        </div>
      ) : (
        <NotUserName>
          <b>Guest</b>
        </NotUserName>
      )}

      {/* {!(device === "mobile") && (
        <React.Fragment>
          <Title onClick={onDropAnchorClick}>
            {userState.isLoggedIn ? "" : "Sign Up/Sign in"}
          </Title>
          <ProfileDropAnchor
            onClick={onDropAnchorClick}
            ismobile={device === "mobile"}
          />
        </React.Fragment>
      )}
      {localUserState.showUserDropdown && (
        <ProfileDropdown ismobile={device === "mobile"}>
          <ProfileDropdownTopNotch ismobile={device === "mobile"} />
          {formList[localUserState.formChoice]}
        </ProfileDropdown>
      )} */}
    </UserInfo>
  )
}

// {userState.isLoggedIn
//   ? formList["signed"]
//   : formList[localUserState.formChoice]}

export default UserSection
