import React from "react"
import axios from "axios"

import { isEmpty } from "lodash"


/**
 * 
 * add this to other query to enable artist search
 * 
 * artists(sort:"title:asc", where:{
                title_contains:"${q}"
              }) {
                id
                title
              }

 */

const fetchResults = ({ query, data, setData, source }) => {
  const getResults = async q => {
    await axios
      .post(
        `${process.env.STRAPI_SERVER_URL}/graphql`,
        {
          query: `
            {
              stations(sort:"title:asc", where:{
                title_contains:"${q}"
              }) {
                id
                title
                premium
                slug
              }

              mediacontents(sort:"title:asc", where:{
                title_contains:"${q}"
              }) {
                id
                title
                uri
                contenttype
                premium
                station {
                  id
                  title
                }
                artist {
                  id
                  title
                }
                show {
                  id
                  title
                }
              }
          }
          `,
        },
        {
          cancelToken: source.token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(res => {
        const results = res.data.data
        setData({
          ...data,
          foundResults: results,
        })
      })
      .catch(error => {
        if (axios.isCancel(error)) {
        } else throw error
      })
  }

  !isEmpty(query) && getResults(query)
  //   return <SearchResultProvider query data setData key />
  return <div></div>
}

export default fetchResults
