import React from "react"
import styled from "styled-components"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"

import {
  PROP_FONT_PRIMARY,
  PROP_FONT_SECONDARY,
} from "../../constants/props"

import ReadMorePara from "../ReadMorePara"


// FEATURED BOX RELATED COMPONENTS
export const FeaturedBox = styled.div`
  background: ${props => (props.noBackground ? "none" : "#464a52")};
  width: 100%;
  margin: 0px auto;
  padding: 5px 0px 25px 0px;
  border-radius: ${MOBILE.box.borderRadius}px;
  display: flex;
  overflow: hidden;
  flex-direction: row;
  position: relative;
  justify-content: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.box.width}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.box.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    padding: 30px 0px;
    width: ${TAB.box.width}px;
    border-radius: ${TAB.box.borderRadius}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.box.width}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.box.width}px;
  }
`
export const FeaturedBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.contentWidth}vw;;
    margin: 0 auto;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.contentWidth}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.contentWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.contentWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.contentWidth}px;
  }
`

export const FeaturedBoxHeading = styled.h2`
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-family: ${PROP_FONT_PRIMARY};
  font-size: ${MOBILE.box.font.mainHeading}px;
  margin-bottom: ${MOBILE.box.marginBottom}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.font.mainHeading}px;
    margin-bottom: ${TAB.box.marginBottom}px;
  }
`

export const FeaturedCardWrapper = styled.div`
  display: flex;
`

export const FeaturedLabelOnTopSection = styled.span`
  flex-wrap: wrap;
  font-family: ${PROP_FONT_SECONDARY};
  margin: 0;
  &::before {
    font-family: ${PROP_FONT_PRIMARY};
    font-weight: bold;
  }
  margin-top: 15px;
  font-size: ${MOBILE.box.font.content}px;
  & b {
    letter-spacing: 1.5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    & span {
      font-size: ${TAB.box.font.content}px;
    }
  }
`

export const FeaturedTitle = styled.h3`
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  letter-spacing: 1.5px;
  font-size: ${MOBILE.box.font.title}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.font.title}px;
  }
`

const ReadMoreParagraph = styled.div`
  color: #fff;
  font-family: ${PROP_FONT_SECONDARY};
  overflow: hidden;
  font-size: ${MOBILE.box.font.content}px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.font.content}px;
  }
  & b {
    letter-spacing: 1.5px;
  }
`
export const FeaturedReadMoreSection = ({ string, offset }) => (
  <ReadMoreParagraph>
    <ReadMorePara string={string} offset={offset} />
  </ReadMoreParagraph>
)

export const FeaturedSocialIconSection = styled.div`
  display: flex;
  flex-shrink: 0;
  padding-bottom: 10px;
  position: relative;
  @media (min-width: ${TAB.minWidth}px) {
    position: unset;
  }
`
export const FeaturedSocialIcon = styled.img`
  margin-right: 15px;
  cursor: pointer;
  margin-top: 10px;
  width: ${MOBILE.box.iconSize}px;
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.box.iconSize}px;
  }
  
` 

export const FeaturedPodcastTitle = styled.h3`
  font-size: ${MOBILE.box.podcasts.font.title}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.podcasts.font.title}px;
  }
`
