import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { MenuLink, MenuLinkWrapper } from "../Styled"
import { navigate } from "gatsby-link"
import {
  ROLE_ARTIST,
  ROLE_STATIONADMIN,
  userPodcastsURL,
  userVideosURL,
  userStationShowsURL,
  userStationURL,
  userStationPresentersURL,
  userArtistURL,
  userLikesURL,
  getBasePath,
} from "../../constants/props"
import { COLOR_MENU_BG } from "../../constants/colors"
import { AppContext } from "../../contexts/AppContext"

const Menu = styled.div`
  background-color: ${COLOR_MENU_BG};
`

const MenuItem = ({ url, text, active }) => {
  return (
    <MenuLinkWrapper>
      <MenuLink active={active} onClick={() => navigate(url)}>
        {text}
      </MenuLink>
    </MenuLinkWrapper>
  )
}

const MainMenu = props => {
  let { selectedCountry, userState } = useContext(AppContext)

  let [selectedItem, setSelectedItem] = useState(null)

  let pathname = props.location ? props.location.pathname : null

  useEffect(() => {
    setSelectedItem(getBasePath(pathname))
  }, [pathname])

  const countryNameSlug = selectedCountry && selectedCountry.slug ? selectedCountry.slug : "all"

  const { setNavigationShow } = props

  return (
    <Menu
      onClick={() => {
        setTimeout(() => {
          setNavigationShow(false)
        }, 500)
      }}
    >
      <MenuItem url="/" text="HOME" active={selectedItem === "/"} />
      <MenuItem
        url={`/radios/${countryNameSlug}`}
        text="Listen to Radio"
        active={selectedItem === "stations"}
      />

      {/*<MenuItem
        url={artistsPageURL(countryID, countryNameSlug)}
        text="Find an Artist"
        active={selectedItem === "artists"}
      />*/}
      {userState.isLoggedIn &&
        userState.role &&
        userState.role.type === ROLE_STATIONADMIN && (
          <MenuItem
            url={userStationURL()}
            text="My Station"
            active={selectedItem === "userstation"}
          />
        )}
      {userState.isLoggedIn &&
        userState.role &&
        userState.role.type === ROLE_STATIONADMIN && (
          <MenuItem
            url={userStationPresentersURL()}
            text="Presenters"
            active={selectedItem === "stationpresenter"}
          />
        )}
      {userState.isLoggedIn &&
        userState.role &&
        userState.role.type === ROLE_STATIONADMIN && (
          <MenuItem
            url={userStationShowsURL()}
            text="Shows"
            active={selectedItem === "stationshows"}
          />
        )}

      {userState.isLoggedIn &&
        userState.role &&
        userState.role.type === ROLE_ARTIST && (
          <MenuItem
            url={userArtistURL()}
            text="My Profile"
            active={selectedItem === "userartist"}
          />
        )}
      {userState.isLoggedIn &&
        userState.role &&
        (userState.role.type === ROLE_STATIONADMIN ||
          userState.role.type === ROLE_ARTIST) && (
          <MenuItem
            url={userPodcastsURL()}
            text="Podcasts"
            active={selectedItem === "userpodcasts"}
          />
        )}
      {userState.isLoggedIn &&
        userState.role &&
        (userState.role.type === ROLE_STATIONADMIN ||
          userState.role.type === ROLE_ARTIST) && (
          <MenuItem
            url={userVideosURL()}
            text="Videos"
            active={selectedItem === "uservideos"}
          />
        )}
      {userState.isLoggedIn && (
        <MenuItem
          url={userLikesURL()}
          text="Your Likes"
          active={selectedItem === "yourlikes"}
        />
      )}
      <MenuItem
        url={"/about"}
        text="ABOUT US"
        active={selectedItem === "about"}
      />
      {!userState.isLoggedIn && (
        <MenuItem
          url={"/login"}
          text="Sign in"
          active={selectedItem === "login"}
        />
      )}
    </Menu>
  )
}

export default MainMenu
