import gql from "graphql-tag"

export const QUERY_ALL_SETTINGS = gql`
  query AllApplicationSettings {
    applicationSettings {
      id
      name
      value
      value_type
    }
  }
`
