import React from "react"
import styled from "styled-components"
import ReadMorePara from "../ReadMorePara"
import {
  MEDIUM,
  LARGE,
  LANDSCAPE,
  MOBILE,
  TAB,
} from "../../constants/breakpoints"
import {
  PROP_FONT_PRIMARY,
  PROP_FONT_SECONDARY,
} from "../../constants/props"

// FEATURE ARTIST COMPONENTS

export const ArtistFeaturedWrapper = styled.div`
  display: flex;
`

export const ArtistFeaturedImageWrapper = styled.div`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.box.artist.featured.image.widthHeight}vw;
    height: ${MOBILE.box.artist.featured.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.box.artist.featured.image.widthHeight}vw;
    height: ${LANDSCAPE.box.artist.featured.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.box.artist.featured.image.widthHeight}px;
    height: ${TAB.box.artist.featured.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.box.artist.featured.image.widthHeight}px;
    height: ${MEDIUM.box.artist.featured.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.box.artist.featured.image.widthHeight}px;
    height: ${LARGE.box.artist.featured.image.widthHeight}px;
  }
`
export const ArtistFeaturedRightTextSection = styled.div`
  display: flex;
  color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: ${MOBILE.box.artist.featured.contentGap}vw;
  width: ${MOBILE.contentWidth -
    MOBILE.box.artist.featured.image.widthHeight -
    MOBILE.box.artist.featured.contentGap}vw;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    margin-left: ${LANDSCAPE.box.artist.featured.contentGap}vw;
    width: ${LANDSCAPE.contentWidth -
      LANDSCAPE.box.artist.featured.image.widthHeight -
      LANDSCAPE.box.artist.featured.contentGap}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-left: ${TAB.box.artist.featured.contentGap}px;
    width: ${TAB.contentWidth -
      TAB.box.artist.featured.image.widthHeight -
      TAB.box.artist.featured.contentGap}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-left: ${MEDIUM.box.artist.featured.contentGap}px;
    width: ${MEDIUM.contentWidth -
      MEDIUM.box.artist.featured.image.widthHeight -
      MEDIUM.box.artist.featured.contentGap}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    margin-left: ${LARGE.box.artist.featured.contentGap}px;
    width: ${LARGE.contentWidth -
      LARGE.box.artist.featured.image.widthHeight -
      LARGE.box.artist.featured.contentGap}px;
  }
`

export const ArtistFeaturedSingleLineText = styled.div`
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${MOBILE.box.artist.featured.font.content}px;
  & div {
    font-family: ${PROP_FONT_PRIMARY};
    padding-right: 3px;
    margin-bottom: 3px;
    display: inline-block;
  }
  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: inline-block;
    }
  }

  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.artist.featured.font.content}px;
    padding-right: 5px;
    margin-bottom: 5px;
  }
`
export const ArtistFeaturedMultiLineText = styled.span`
  margin: 0;
  font-size: ${MOBILE.box.artist.featured.font.content}px;

  & div {
    font-family: ${PROP_FONT_PRIMARY};
    margin-bottom: 3px;
    padding-right: 3px;
    display: inline-block;
  }

  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: inline-block;
    }
  }

  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.artist.featured.font.content}px;
    margin-bottom: 5px;
    padding-right: 5px;
  }
`
export const ArtistFeaturedTitle = styled.h3`
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  letter-spacing: 1.5px;
  font-size: ${MOBILE.box.artist.featured.font.title}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.artist.featured.font.title}px;
  }
`

// other section

export const ArtistCardSection = styled.div`
  display: flex;
  margin: 20px 0px;
  padding-top: 15px;
  padding-bottom: 40px;
  border-bottom: 1px solid #fff;
  :last-of-type {
    border: 0;
  }
  flex-grow: 1;
`

export const ArtistsRightInfoSection = styled.div`
  display: flex;
  flex: 1;
  color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: ${MOBILE.artists.grid.contentGap}vw;
  width: ${MOBILE.contentWidth -
    MOBILE.artists.grid.image.widthHeight -
    MOBILE.artists.grid.contentGap}vw;

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    margin-left: ${LANDSCAPE.artists.grid.contentGap}vw;
    width: ${LANDSCAPE.contentWidth -
      LANDSCAPE.artists.grid.image.widthHeight -
      LANDSCAPE.artists.grid.contentGap}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-left: ${TAB.artists.grid.contentGap}px;
    width: ${TAB.contentWidth -
      TAB.artists.grid.image.widthHeight -
      TAB.artists.grid.contentGap}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-left: ${MEDIUM.artists.grid.contentGap}px;
    width: ${MEDIUM.contentWidth -
      MEDIUM.artists.grid.image.widthHeight -
      MEDIUM.artists.grid.contentGap}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    margin-left: ${LARGE.artists.grid.contentGap}px;
    width: ${LARGE.contentWidth -
      LARGE.artists.grid.image.widthHeight -
      LARGE.artists.grid.contentGap}px;
  }
`

export const ArtistImageWrapper = styled.div`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.artists.grid.image.widthHeight}vw;
    height: ${MOBILE.artists.grid.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.artists.grid.image.widthHeight}vw;
    height: ${LANDSCAPE.artists.grid.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.artists.grid.image.widthHeight}px;
    height: ${TAB.artists.grid.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.artists.grid.image.widthHeight}px;
    height: ${MEDIUM.artists.grid.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.artists.grid.image.widthHeight}px;
    height: ${LARGE.artists.grid.image.widthHeight}px;
  }
`
export const ArtistInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  flex-wrap: wrap;
  text-overflow: ellipsis;
`
export const ArtistTitle = styled.h3`
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  letter-spacing: 1.5px;
  font-size: ${MOBILE.artists.grid.font.title}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.artists.grid.font.title}px;
  }
`
export const ArtistSingleLineText = styled.div`
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${MOBILE.artists.grid.font.content}px;
  & b {
    letter-spacing: 1.5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.artists.grid.font.content}px;
  }
`
export const ArtistGridMultiLineText = styled.span`
  margin: 0;
  font-size: ${MOBILE.artists.grid.font.content}px;

  & div {
    font-family: ${PROP_FONT_PRIMARY};
    margin-bottom: 3px;
    padding-right: 3px;
    display: inline-block;
  }

  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: inline-block;
    }
  }

  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.artists.grid.font.content}px;
    margin-bottom: 5px;
    padding-right: 5px;
  }
`
const ArtistsReadMoreParagraph = styled.div`
  color: #fff;
  font-family: ${PROP_FONT_SECONDARY};
  overflow: hidden;
  font-size: ${MOBILE.box.artist.details.font.content}px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.artist.details.font.content}px;
  }
  & b {
    letter-spacing: 1.5px;
  }
`
export const ArtistReadMoreSection = ({ string, offset }) => (
  <ArtistsReadMoreParagraph>
    <ReadMorePara string={string} offset={offset} />
  </ArtistsReadMoreParagraph>
)

export const ArtistShareLikeSection = styled.div`
  display: flex;
`
export const ArtistShareLikeIcon = styled.img`
  width: 25px;
  margin-right: 15px;
  cursor: pointer;
  margin-top: 10px;
`

// ARTIST DETAILS SECTION
export const ArtistDetailsSingleLineText = styled.div`
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${MOBILE.box.artist.details.font.content}px;
  & b {
    letter-spacing: 1.5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.artist.details.font.content}px;
  }
`

export const ArtistDetailsTitle = styled.h3`
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  letter-spacing: 1.5px;
  font-size: ${MOBILE.box.artist.details.font.title}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.artist.details.font.title}px;
  }
`

export const ArtistsDetailsImageWrapper = styled.div`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.box.artist.details.cover.width}vw;
    height: ${MOBILE.box.artist.details.cover.height}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${MOBILE.box.artist.details.cover.width}vw;
    height: ${MOBILE.box.artist.details.cover.height}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.box.artist.details.cover.width}vw;
    height: ${TAB.box.artist.details.cover.height}vw;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.box.artist.details.cover.width}px;
    height: ${MEDIUM.box.artist.details.cover.height}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.box.artist.details.cover.width}px;
    height: ${LARGE.box.artist.details.cover.height}px;
  }
`
export const ArtistDetailsMultiLineText = styled.span`
  margin: 0;
  font-size: ${MOBILE.box.artist.details.font.content}px;

  & div {
    font-family: ${PROP_FONT_PRIMARY};
    margin-bottom: 3px;
    padding-right: 3px;
    display: inline-block;
  }

  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: inline-block;
    }
  }

  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.artist.details.font.content}px;
    margin-bottom: 5px;
    padding-right: 5px;
  }
`
export const ArtistDetailsRightTextSection = styled.div`
  display: flex;
  flex: 1;
  color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: ${MOBILE.box.artist.details.contentGap}vw;
  width: ${MOBILE.contentWidth -
    MOBILE.box.artist.details.image.widthHeight -
    MOBILE.box.artist.details.contentGap}vw;

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    margin-left: ${LANDSCAPE.box.artist.details.contentGap}vw;
    width: ${LANDSCAPE.contentWidth -
      LANDSCAPE.box.artist.details.image.widthHeight -
      LANDSCAPE.box.artist.details.contentGap}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-left: ${TAB.box.artist.details.contentGap}px;
    width: ${TAB.contentWidth -
      TAB.box.artist.details.image.widthHeight -
      TAB.box.artist.details.contentGap}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-left: ${MEDIUM.box.artist.details.contentGap}px;
    width: ${MEDIUM.contentWidth -
      MEDIUM.box.artist.details.image.widthHeight -
      MEDIUM.box.artist.details.contentGap}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    margin-left: ${LARGE.box.artist.details.contentGap}px;
    width: ${LARGE.contentWidth -
      LARGE.box.artist.details.image.widthHeight -
      LARGE.box.artist.details.contentGap}px;
  }
`
