export const LARGE = {
  minWidth: 1500,
  page: 1500,
  contentWidth: 1000,
  websitePadding: 10,
  layout: {
    paddingTop: 15, // in px
    videoPlayerControls: 14, // in px
  },
  header: {
    height: 9.1, // in vh
    logoHeight: 8, // in vh
    subMenuFont: 0, // font of sub menu also its height in header
    bottomMargin: 0, // Bottom margin of header
  },
  search: {
    height: 30, // in vh
    borderRadius: 4,
    font: 14,
    result: {
      font: 16,
      top: 8.3, // in vh
    },
  },
  countrySlider: {
    heading: {
      paddingTop: 0,
      paddingBottom: 0,
      font: 28,
      subHeadingFont: 28,
      subTextFont: 14,
    },
    search: {
      width: 400, // in px
      marginBottom: 20,
    },
    flag: {
      large: {
        font: 22, // in px
        width: 250, // in px
        height: 145, // in px
        borderRadius: 20, // in px
      },
      small: {
        flagRow: 1480, // in px
        font: 16,
        padding: 30,
        width: 125, // in px,
        height: 72.5, // in px
        selectedWidthIncrease: 0, // should not be more than padding
        selectedHeightIncrease: 0, // should not be more than padding
        borderRadius: 10, // in px
      },
    },
  },
  player: {
    topMargin: 40, // margin from header, this helps in aligning it to ads 
    controls: {
      bottom: {
        height: 75, // in px, height of bottom player controls
      },
    },
  },
  box: {
    width: 1200, // in px
    height: 380, // in px
    borderRadius: 40,
    anchorHeight: 28,
    carouselPaddingRight: 50, // in px
    marginBottom: 20, // gap between heading and image/content section
    iconSize: 20, // like and share button in px
    arrowIconWidth: 25,
    font: {
      mainHeading: 36, // Font size of top heading
      title: 24,
      content: 18,
    },
    station: {
      featured: {
        contentGap: 15, // in px
        textOffset: 250,
        image: {
          widthHeight: 250,
          borderRadius: 20,
        },
      },
      details: {
        contentGap: 30,
        textOffset: 250,
        font: {
          title: 18,
          content: 14,
        },
        image: {
          widthHeight: 250, // in px
          borderRadius: 20,
        },
      },
    },
    artist: {
      featured: {
        contentGap: 30,
        textOffset: 250,
        image: {
          widthHeight: 180,
        },
      },
      details: {
        contentGap: 50,
        textOffset: 250,
        font: {
          title: 24,
          content: 18,
        },
        image: {
          widthHeight: 180, // in px
          borderRadius: 20,
        },
        cover: {
          width: 400,
          height: 300,
        },
      },
    },
    podcasts: {
      image: {
        widthHeight: 250,
        borderRadius: 30,
      },
      font: {
        title: 24,
        content: 18,
      },
      contentGap: 50, // gap between image and content
      textSectionWidth: 680, // For smooth sliding experience of text in gray box we need to give fix width
      textOffset: 250, // Read more text characters to allow
    },
    videos: {
      featured: {
        textOffset: 120,
      },
      playing: {
        width: 1200, // width of video when it is played
        height: 560, // height of video when it is played
        floatingWidth: 500,
        floatingHeight: 500 * 0.56,
      },
      image: {
        width: 1000, // width of image in featured image box (videos page)
        height: 380, // height of image in featured image box (videos page)
      },
    },
  },
  stations: {
    grid: {
      cardRow: 1125,
      cardWidth: 375,
      image: {
        widthHeight: 251,
        borderRadius: 30,
      },
    },
    slider: {
      slides: 3,
      cardRow: 1125, // in px
      textOffset: 100,
      font: {
        title: 20,
        content: 14,
      },
      image: {
        widthHeight: 250, // in px
        paddingRight: 125,
        borderRadius: 20,
      },
    },
  },
  podcasts: {
    font: {
      title: 18,
      content: 14,
    },
    slider: {
      slides: 3,
      cardRow: 1125, // in px
      textOffset: 100,
      font: {
        title: 20,
        content: 14,
      },
      image: {
        widthHeight: 250, // in px
        paddingRight: 125,
        borderRadius: 20,
      },
    },
    grid: {
      imageWidthHeight: 250, // in px
    },
    list: {
      contentGap: 20, // Space between image card and content,
      shareLikeIconSize: 20,
      textOffset: 250, // read more text offset
      font: {
        mainHeading: 30, // station name and sort heading
        title: 20, // podcast title H3
        date: 15, // podcast date span
      },
      image: {
        borderRadius: 30,
        widthHeight: 250,
      },
    },
  },
  videos: {
    slider: {
      textOffset: 120,
      slides: 2,
      cardRow: 1040, // in px
      image: {
        width: 480,
        height: 480 * 0.56,
      },
      font: {
        title: 18,
        content: 14,
      },
    },
    grid: {
      cardRow: 1040,
      cardWidth: 520,
      image: {
        width: 480,
        height: 480 * 0.56,
      },
      font: {
        title: 14,
        content: 12,
      },
    },
  },
  presenters: {
    slider: {
      slides: 5,
      cardRow: 1050, // in px
      cardWidth: 160, // in px
      image: {
        widthHeight: 120,
      },
      font: {
        title: 18,
        content: 14,
      },
    },
  },
  artists: {
    grid: {
      contentGap: 50,
      textOffset: 250,
      image: {
        widthHeight: 250, // in px
      },
      font: {
        title: 18,
        content: 14,
      },
    },
  },
  publicPages: {
    signin: {
      width: 600,
      font: {
        heading: 28,
      },
    },
    signup: {
      width: 1500,
      formWidth: 550,
      font: {
        heading: 28,
      },
    },
    HeadingBottomMargin: 20,
  },
  ads: {
    sideAdsWidth: 150,
    bottomAdsWidth: 800,
    bottomAdsHeight: 800 * 0.15625,
  },
  userdata: {
    formWidth: 600,
  },
}
