import React, { createContext, useState } from "react"
import { navigate } from "gatsby-link"

import "react-notifications/lib/notifications.css"
// import { navigate } from "@reach/router"
import {
  PROP_MENU_LINK_LOAD_TIMEOUT,
  getLinkTypeData,
  objectToQueryString,
  MEDIA_TYPE_AUDIO,
  VIDEO_MODE_CLOSED,
  DATA_TYPE_STATION,
  DATA_TYPE_ARTIST,
  DATA_TYPE_ARTIST_PODCAST,
  DATA_TYPE_ARTIST_VIDEO,
  DATA_TYPE_STATION_PODCAST,
  DATA_TYPE_STATION_VIDEO,
} from "../constants/props"

import { DEVICE_LARGE } from "../constants/breakpoints"

export const AppContext = createContext()

const AppContextProvider = props => {
  // Menu State & Methods

  const defaultValues = props.defaultValues ? props.defaultValues : {}

  const navigateToLink = ({
    type,
    id,
    title,
    timeout = PROP_MENU_LINK_LOAD_TIMEOUT,
    data = null, // Data of self or useful dta
    neighbours = [],
    queryParams,
  }) => {
    const { linkAddr } = getLinkTypeData(type, {
      id,
    })
    // Navigate to particular link along with the
    setTimeout(() => {
      navigate(
        `/${linkAddr}` +
          (queryParams ? "?" + objectToQueryString(queryParams) : ""),
        {
          replace: false,
          state: {
            type,
            id,
            title,
            neighbours,
            data,
          },
        }
      )
      //minimizePlayer(true)
    }, timeout)
  }

  const navigateToHome = ({ timeout = PROP_MENU_LINK_LOAD_TIMEOUT }) => {
    setTimeout(() => {
      navigate(`/`)
    }, timeout)
  }

  // User State & Methods
  const [userState, setUserState] = useState({
    isLoggedIn: false,
    requestSignin: false,
    // User Data From Response
    confirmed: null,
    blocked: null,
    id: null,
    firstname: null,
    lastname: null,
    username: null,
    email: null,
    provider: null,
    role: null,
    stationID: null,
    station: null,
    artist: null,
  })

  const setUserLoggedIn = () => {
    setUserState({ ...userState, isLoggedIn: true })
  }

  const resetUserLoggedIn = () => {
    setUserState({
      isLoggedIn: false,
      requestSignin: false,
      // User Data From Response
      confirmed: null,
      blocked: null,
      id: null,
      firstname: null,
      lastname: null,
      username: null,
      email: null,
      provider: null,
      role: null,
      stationID: null,
      station: null,
      artist: null,
    })
    // reset user likes as well on sign out
    setUserLikes(null)
  }

  const setRequestSignin = () => {
    setUserState({ ...userState, requestSignin: true })
  }

  const resetRequestSignin = () => {
    setUserState({ ...userState, requestSignin: false })
  }

  const [device, setDeviceType] = useState(undefined)

  // set device type if flag is true as useMediaQuery is used for flag and only when media query is true
  // we need to set passed device type
  const setDevice = (flag, type) => {
    if (flag && device !== type) {
      setDeviceType(type)
    }
  }

  const [ctxPlayer, setCtxPlayer] = useState({
    type: MEDIA_TYPE_AUDIO,
    id: null,
    uri: null,
    title: null,
    subtitle: null,
    subtitles: [],
    volume: 100,
    seek: 0,
    buffering: false,
    live: true,
    playlist: [],
    minimize: false,
    station: undefined,
    artist: undefined,
    videoMode: VIDEO_MODE_CLOSED,
    about: null,
    viewed: 0,
    playing: false,
    dataType: null,
  })

  /**
   *
   * @param {type} audio/video - Audio are played in the background while video type cause player to show video
   */
  function updatePlayer({
    type,
    id,
    uri,
    title,
    subtitle,
    live,
    streamuri,
    preview,
    playlist,
    data,
    station,
    artist,
    videoMode,
    about,
    subtitles,
    viewed,
    dataType,
    playing,
  }) {
    setCtxPlayer({
      ...ctxPlayer,
      type,
      id,
      uri,
      streamuri,
      title,
      subtitle,
      preview,
      live,
      playlist,
      data,
      station,
      artist,
      videoMode,
      about,
      subtitles,
      viewed,
      dataType,
      playing,
    })
  }

  function closePlayer() {
    setCtxPlayer({
      type: "audio",
      id: null,
      uri: null,
      title: null,
      subtitle: null,
      volume: 100,
      seek: 0,
      buffering: false,
      live: true,
      playlist: [],
      station: null,
      artist: null,
      videoMode: VIDEO_MODE_CLOSED,
      viewed: 0, // number of times this media is viewed
      dataType: null,
      playing: false,
    })
  }

  function minimizePlayer(minimize) {
    setCtxPlayer({
      ...ctxPlayer,
      minimize,
    })
  }

  function destroyPlayer({ message }) {}

  const [countries, setCountryList] = useState([])
  const [selectedCountry, setCurrentCountry] = useState(undefined)
  const [previousCountry, setPreviousCountry] = useState(undefined)

  const setSelectedCountry = country => {
    if (selectedCountry) setPreviousCountry({ ...selectedCountry })
    setCurrentCountry(country)
  }

  const [showShare, setShowShare] = useState(null)

  const [showLoader, setShowLoader] = useState(null)

  const [userLikes, setLikesData] = useState(null)

  const basePrefix = `${process.env.STRAPI_SERVER_URL}`

  const setCountries = countries => {
    let countryList =
      countries && countries.length
        ? countries.map(c => ({
            id: c.id,
            value: c.id,
            name: c.title,
            label: c.title,
            timezone: c.Timezone,
            slug: c.slug,
            url:
              c && c.image && c.image.url
                ? c.image.url.startsWith("http")
                  ? c.image.url
                  : basePrefix + c.image.url
                : "",
          }))
        : []

    setCountryList(countryList)
  }

  const setUserLikes = userLikes => {
    if (userLikes && userLikes.length) {
      // iterate over all data and divide in different sections
      var likesData = {
        station: [],
        artist: [],
        stationPodcast: [],
        stationVideo: [],
        artistPodcast: [],
        artistVideo: [],
      }
      userLikes.forEach(ul => {
        switch (ul.type) {
          case DATA_TYPE_STATION:
            if (ul.station && ul.station.id)
              likesData.station.push({
                id: ul.id,
                likeID: ul.station.id,
                data: { ...ul.station },
              })
            break
          case DATA_TYPE_ARTIST:
            if (ul.artist && ul.artist.id)
              likesData.artist.push({
                id: ul.id,
                likeID: ul.artist.id,
                data: { ...ul.artist },
              })
            break
          case DATA_TYPE_STATION_PODCAST:
            if (ul.mediacontent && ul.mediacontent.id)
              likesData.stationPodcast.push({
                id: ul.id,
                likeID: ul.mediacontent.id,
                data: { ...ul.mediacontent },
              })
            break
          case DATA_TYPE_STATION_VIDEO:
            if (ul.mediacontent && ul.mediacontent.id)
              likesData.stationVideo.push({
                id: ul.id,
                likeID: ul.mediacontent.id,
                data: { ...ul.mediacontent },
              })
            break
          case DATA_TYPE_ARTIST_PODCAST:
            if (ul.mediacontent && ul.mediacontent.id)
              likesData.artistPodcast.push({
                id: ul.id,
                likeID: ul.mediacontent.id,
                data: { ...ul.mediacontent },
              })
            break
          case DATA_TYPE_ARTIST_VIDEO:
            if (ul.mediacontent && ul.mediacontent.id)
              likesData.artistVideo.push({
                id: ul.id,
                likeID: ul.mediacontent.id,
                data: { ...ul.mediacontent },
              })
            break
          default:
            break
        }
      })
      setLikesData(likesData)
    } else {
      setLikesData({
        station: [],
        artist: [],
        stationPodcast: [],
        stationVideo: [],
        artistPodcast: [],
        artistVideo: [],
      })
    }
  }

  const [appSettings, setAppSettingData] = useState({})

  const setAppSettings = appSettings => {
    let settings = {}
    if (appSettings && appSettings.length > 0) {
      appSettings.forEach(s => {
        switch (s.value_type) {
          case "FLOAT":
            settings[s.name] = parseFloat(s.value)
            break
          case "INT":
            settings[s.name] = parseInt(s.value)
            break
          default:
            settings[s.name] = s.value
        }
      })
    }
    console.log("[APP-SETTINGS] settings is ", settings)
    setAppSettingData(settings)
  }

  // prettier-ignore
  return (
    <AppContext.Provider
    value={{ 
      navigateToLink, 
      navigateToHome,  
      userState, 
      setUserState, 
      setLikesData, 
      setUserLoggedIn, 
      resetUserLoggedIn, 
      setRequestSignin, 
      resetRequestSignin,
      device, 
      setDevice,
      ctxPlayer, 
      closePlayer, 
      updatePlayer, 
      destroyPlayer, 
      minimizePlayer,
      countries: countries.length > 0? countries: defaultValues.countries, 
      setCountries, 
      selectedCountry: selectedCountry? selectedCountry: defaultValues.selectedCountry, 
      setSelectedCountry, 
      previousCountry,
      setPreviousCountry, 
      showShare, 
      setShowShare, 
      userLikes, 
      setUserLikes, 
      showLoader, 
      setShowLoader, 
      appSettings, 
      setAppSettings,
      seoStations: defaultValues.seoStations
    }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
