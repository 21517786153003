import styled from "styled-components"

import {
  COLOR_OUTLINE_LIGHT,
  COLOR_SEARCH_INPUT_PLACEHOLDER_TEXT,
  COLOR_ERROR_TEXT,
} from "../../constants/colors"

import { MOBILE, TAB, MEDIUM, LARGE } from "../../constants/breakpoints"

import {
  PROP_NORMAL_FORM_INPUT_FIELD_WIDTH,
  PROP_NORMAL_FORM_INPUT_FIELD_HEIGHT,
  PROP_NORMAL_FORM_INPUT_BORDER_THICKNESS,
  PROP_NORMAL_FORM_MOBILE_INPUT_FIELD_HEIGHT,
  PROP_NORMAL_FORM_INPUT_BORDER_RADIUS,
  PROP_NORMAL_FORM_MOBILE_INPUT_BORDER_RADIUS,
} from "../../constants/props"

export const Label = styled.label`
  width: 100%;
  padding: 5px 0px;
  font-size: 16px;
  color: #fff;
`
export const EpisodeLabel = styled.label`
  width: 100%;
  padding: 5px 0px;
  font-size: 16px;
  color: black;
`

export const FieldArrayLabel = styled.label`
  width: 100%;
  padding: 5px 0px;
  font-size: 16px;
  color: black;
`

export const Form = styled.form`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.userdata.formWidth};
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.userdata.formWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.userdata.formWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.userdata.formWidth}px;
  }
`
export const FormInput = styled.input`
  height: ${props =>
    props.ismobile
      ? PROP_NORMAL_FORM_MOBILE_INPUT_FIELD_HEIGHT
      : PROP_NORMAL_FORM_INPUT_FIELD_HEIGHT};
  width: ${props =>
    props.ismobile ? "89vw" : PROP_NORMAL_FORM_INPUT_FIELD_WIDTH};
  border: ${PROP_NORMAL_FORM_INPUT_BORDER_THICKNESS} solid
    ${COLOR_OUTLINE_LIGHT};
  border-radius: ${props =>
    props.ismobile
      ? PROP_NORMAL_FORM_MOBILE_INPUT_BORDER_RADIUS
      : PROP_NORMAL_FORM_INPUT_BORDER_RADIUS};
  outline: none;
  box-shadow: none;
  background: #e8f0fe;
  padding: 5px;
  font-size: 0.8rem;
  color: black;

  ::placeholder {
    color: ${COLOR_SEARCH_INPUT_PLACEHOLDER_TEXT};
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 1rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 1.2rem;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: 1.2rem;
  }
`
export const FormSelect = styled.select`
  height: ${props =>
    props.ismobile
      ? PROP_NORMAL_FORM_MOBILE_INPUT_FIELD_HEIGHT
      : PROP_NORMAL_FORM_INPUT_FIELD_HEIGHT};
  width: ${props =>
    props.ismobile ? "89vw" : PROP_NORMAL_FORM_INPUT_FIELD_WIDTH};
  border: ${PROP_NORMAL_FORM_INPUT_BORDER_THICKNESS} solid
    ${COLOR_OUTLINE_LIGHT};
  border-radius: ${props =>
    props.ismobile
      ? PROP_NORMAL_FORM_MOBILE_INPUT_BORDER_RADIUS
      : PROP_NORMAL_FORM_INPUT_BORDER_RADIUS};
  outline: none;
  box-shadow: none;
  background: #e8f0fe;
  color: #000;
  padding: 0px;
  font-size: 0.8rem;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 1rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 1.2rem;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: 1.2rem;
  }
  ::placeholder {
    color: ${COLOR_SEARCH_INPUT_PLACEHOLDER_TEXT};
  }
`

export const FormTextarea = styled.textarea`
  width: 100%;
  height: 19.1rem;
  font-weight: 400;
  font-size: 0.8rem;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 1rem;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 1.2rem;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: 1.2rem;
  }
  background-color: #e8f0fe;
  line-height: 18px;
  padding: 1rem;
  outline: 0px;
  color: black;
  border: ${PROP_NORMAL_FORM_INPUT_BORDER_THICKNESS} solid
    ${COLOR_OUTLINE_LIGHT};
  border-radius: ${props =>
    props.ismobile
      ? PROP_NORMAL_FORM_MOBILE_INPUT_BORDER_RADIUS
      : PROP_NORMAL_FORM_INPUT_BORDER_RADIUS};
`
export const FileTypeLabel = styled.label`
  width: 100%;
  height: 200px;
  border: 4px solid #e1e9f7;
  background: #e8f0fe;
  margin: 0;
  padding: 88px 0px;
  outline: none;
  text-align: center;
  cursor: pointer;
  position: relative;
  color: black;
`

export const AudioFileLabel = styled.label`
  width: 100%;
  border: 4px solid #e1e9f7;
  background: #e8f0fe;
  color: black;
  margin: 0;
  padding: 15px 0px;
  outline: none;
  text-align: center;
  cursor: pointer;
  position: relative;
`
export const AudioFilePlayWrapper = styled.div``

export const AudioFilePlay = styled.img`
  text-align: center;
  padding: 5px 0px;
  margin: auto;
  display: block;
`
export const FormInputFile = styled.input`
  opacity: 0;
`
export const Image = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  max-width: 100%;
  max-height: 100%;
  z-index: 3;
  margin: auto;
`
export const ErrorText = styled.div`
  color: ${COLOR_ERROR_TEXT};
`
export const AddEpisode = styled.button`
  background: #e8f0fe;
  padding: 10px 20px;
  font-size: 16px;
  color: #000;
`

export const FieldArraySection = styled.div`
  background: #ddd;
  padding: 10px;
  padding-bottom: 0px;
  margin: 15px 30px;
`
export const FieldArrayDeleteButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  & button {
    color: black;
    background: #e8f0fe;
    padding: 5px 25px;
    margin: 15px 0px;
  }
`

export const FieldArrayAddButtonContainer = styled.div`
  display: flex;
  & button {
    color: black;
    background: #e8f0fe;
    padding: 5px 20px;
    font-size: 16px;
    margin-top: 10px;
    border-radius: 5px;
  }
`

export const FormButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
export const SubmitButton = styled.button.attrs(props => ({
  type: "submit",
}))`
  font-size: 14px;
  margin: 20px 20%;
  color: white;
  border-radius: 5px;
  padding: 8px 50px;
  background-color: #3498ff;
`

export const OperationWrapper = styled.div`
  width: auto;
  display: flex;
  height: 32px;
  margin-top: 10px;
  color: #fff;
  z-index: 99;
  cursor: pointer;
  justify-content: center;
  & img {
    width: 24px;
    height: 24px;
  }
`
export const OperationIcon = styled.img`
  background: transparent;
  margin: 0px 5px;
  ::selection {
    color: none;
    background: none;
  }
`
