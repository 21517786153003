import React from "react"
import ReadMorePara from "../ReadMorePara"
import styled from "styled-components"
import {
  MEDIUM,
  LARGE,
  LANDSCAPE,
  MOBILE,
  TAB,
} from "../../constants/breakpoints"
import {
  PROP_FONT_PRIMARY,
  PROP_FONT_SECONDARY,
} from "../../constants/props"

// FEATURED PODCASTS COMPONENT

export const PodcastFeaturedWrapper = styled.div`
  display: flex;
`
export const PodcastFeaturedImageWrapper = styled.div`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.box.podcasts.image.widthHeight}vw;
    height: ${MOBILE.box.podcasts.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.box.podcasts.image.widthHeight}vw;
    height: ${LANDSCAPE.box.podcasts.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.box.podcasts.image.widthHeight}px;
    height: ${TAB.box.podcasts.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.box.podcasts.image.widthHeight}px;
    height: ${MEDIUM.box.podcasts.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.box.podcasts.image.widthHeight}px;
    height: ${LARGE.box.podcasts.image.widthHeight}px;
  }
`

export const PodcastFeaturedRightTextSection = styled.div`
  display: flex;
  color: #fff;
  margin-left: ${MOBILE.box.podcasts.contentGap}vw;
  flex-direction: column;
  justify-content: flex-start;
  width: ${MOBILE.contentWidth -
    MOBILE.box.podcasts.image.widthHeight -
    MOBILE.box.podcasts.contentGap}vw;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    margin-left: ${LANDSCAPE.box.podcasts.contentGap}vw;
    width: ${LANDSCAPE.contentWidth -
      LANDSCAPE.box.podcasts.image.widthHeight -
      LANDSCAPE.box.podcasts.contentGap}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-left: ${TAB.box.podcasts.contentGap}px;
    width: ${TAB.contentWidth -
      TAB.box.podcasts.image.widthHeight -
      TAB.box.podcasts.contentGap}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-left: ${MEDIUM.box.podcasts.contentGap}px;
    width: ${MEDIUM.contentWidth -
      MEDIUM.box.podcasts.image.widthHeight -
      MEDIUM.box.podcasts.contentGap}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    margin-left: ${LARGE.box.podcasts.contentGap}px;
    width: ${LARGE.contentWidth -
      LARGE.box.podcasts.image.widthHeight -
      LARGE.box.podcasts.contentGap}px;
  }
`
export const PodcastFeaturedTitle = styled.h3`
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  letter-spacing: 1.5px;
  font-size: ${MOBILE.box.podcasts.font.title}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.podcasts.font.title}px;
  }
`
// Single line texts optionally with bold labels
export const PodcastFeaturedSingleLineText = styled.div`
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${MOBILE.box.podcasts.font.content}px;
  & div {
    font-family: ${PROP_FONT_PRIMARY};
    padding-right: 3px;
    margin-bottom: 3px;
    display: inline-block;
  }
  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: inline-block;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.podcasts.font.content}px;
    padding-right: 5px;
    margin-bottom: 5px;
  }
`
export const PodcastFeaturedMultiLineText = styled.span`
  margin: 0;
  font-size: ${MOBILE.box.podcasts.font.content}px;

  & div {
    font-family: ${PROP_FONT_PRIMARY};
    margin-bottom: 3px;
    padding-right: 3px;
    display: inline-block;
  }

  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: inline-block;
    }
  }

  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.podcasts.font.content}px;
    margin-bottom: 5px;
    padding-right: 5px;
  }
`

// PODCAST LIST COMPONENTS
export const PodcastAllWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
`

export const PodcastListImageWrapper = styled.div`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.podcasts.list.image.widthHeight}vw;
    height: ${MOBILE.podcasts.list.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.podcasts.list.image.widthHeight}vw;
    height: ${LANDSCAPE.podcasts.list.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.podcasts.list.image.widthHeight}px;
    height: ${TAB.podcasts.list.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.podcasts.list.image.widthHeight}px;
    height: ${MEDIUM.podcasts.list.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.podcasts.list.image.widthHeight}px;
    height: ${LARGE.podcasts.list.image.widthHeight}px;
  }
`

export const PodcastListRightTextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  letter-spacing: 1.5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-left: ${MOBILE.podcasts.list.contentGap}vw;
    width: ${MOBILE.contentWidth -
      MOBILE.podcasts.list.contentGap -
      MOBILE.podcasts.list.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    margin-left: ${LANDSCAPE.podcasts.list.contentGap}vw;
    width: ${LANDSCAPE.contentWidth -
      LANDSCAPE.podcasts.list.contentGap -
      LANDSCAPE.podcasts.list.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-left: ${TAB.podcasts.list.contentGap}px;
    width: ${TAB.contentWidth -
      TAB.podcasts.list.contentGap -
      TAB.podcasts.list.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-left: ${MEDIUM.podcasts.list.contentGap}px;
    width: ${MEDIUM.contentWidth -
      MEDIUM.podcasts.list.contentGap -
      MEDIUM.podcasts.list.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    margin-left: ${LARGE.podcasts.list.contentGap}px;
    width: ${LARGE.contentWidth -
      LARGE.podcasts.list.contentGap -
      LARGE.podcasts.list.image.widthHeight}px;
  }
`
export const PodcastListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #fff;
  :last-of-type {
    border: 0;
  }
  flex-grow: 1;
  padding-top: ${MOBILE.podcasts.list.paddingTop}px;
  padding-bottom: ${MOBILE.podcasts.list.paddingBottom}px;

  @media (min-width: ${TAB.minWidth}px) {
    margin: ${TAB.podcasts.list.marginTop}px 0px;
    padding-top: ${TAB.podcasts.list.paddingTop}px;
    padding-bottom: ${TAB.podcasts.list.paddingBottom}px;
  }
`
export const PodcastWrapper = styled.div`
  display: flex;
`

export const PodcastInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 16px;
  flex-wrap: wrap;
  text-overflow: ellipsis;
`

export const PodcastTitleWrapper = styled.div`
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  letter-spacing: 1.5px;
  display: flex;
  flex: 1;
  justify-content: space-between;
`

export const PodcastTitle = styled.h3`
  font-size: ${MOBILE.podcasts.list.font.title}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.podcasts.list.font.title}px;
  }
`
export const PodcastDate = styled.span`
  font-size: ${MOBILE.podcasts.list.font.date}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.podcasts.list.font.date}px;
  }
`
export const PodcastLikeShareSection = styled.div`
  display: flex;
  flex-shrink: 0;
  position: relative;
  @media (min-width: ${TAB.minWidth}px) {
    position: unset;
  }
`
export const PodcastLikeShareIcon = styled.img`
  margin-right: 15px;
  cursor: pointer;
  margin-top: 0px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.podcasts.list.shareLikeIconSize}px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.podcasts.list.shareLikeIconSize}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.podcasts.list.shareLikeIconSize}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.podcasts.list.shareLikeIconSize}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.podcasts.list.shareLikeIconSize}px;
  }
`

export const PodcastListMultiLineText = styled.div`
  margin: 0;
  font-size: ${MOBILE.podcasts.list.font.content}px;

  & div {
    font-family: ${PROP_FONT_PRIMARY};
    margin-bottom: 3px;
    padding-right: 3px;
    display: inline-block;
  }

  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: inline-block;
    }
  }

  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.podcasts.list.font.content}px;
    margin-bottom: 5px;
    padding-right: 5px;
  }
`

export const PodcastListSingleLineText = styled.div`
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${MOBILE.podcasts.list.font.content}px;
  & div {
    font-family: ${PROP_FONT_PRIMARY};
    padding-right: 3px;
    margin-bottom: 3px;
    display: inline-block;
  }
  & span {
    font-family: ${PROP_FONT_SECONDARY};
    display: block;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    & span {
      display: inline-block;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.podcasts.list.font.content}px;
    padding-right: 5px;
    margin-bottom: 5px;
  }
`

/**
 * PODCASTS SLIDER COMPONENTS
 */
 
export const PodcastSliderWrappper = styled.div`
  display: flex;
  flex-direction: column;
`
export const PodcastSliderImageWrapper = styled.div`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.podcasts.slider.image.widthHeight}vw;
    height: ${MOBILE.podcasts.slider.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.podcasts.slider.image.widthHeight}vw;
    height: ${LANDSCAPE.podcasts.slider.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.podcasts.slider.image.widthHeight}px;
    height: ${TAB.podcasts.slider.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.podcasts.slider.image.widthHeight}px;
    height: ${MEDIUM.podcasts.slider.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.podcasts.slider.image.widthHeight}px;
    height: ${LARGE.podcasts.slider.image.widthHeight}px;
  }
`
export const PodcastSliderInfoWrapper = styled.div`
  margin-top: ${MOBILE.podcasts.slider.textMarginTop}vh;
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: ${TAB.podcasts.slider.textMarginTop}px;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.podcasts.slider.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.podcasts.slider.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.podcasts.slider.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.podcasts.slider.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.podcasts.slider.image.widthHeight}px;
  }
`
export const PodcastsSliderTitle = styled.div`
  display: block;
  font-family: ${PROP_FONT_PRIMARY};
  font-size: ${MOBILE.podcasts.slider.font.title}px;
  text-align: left;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.podcasts.slider.font.title}px;
  }
`
export const PodcastSliderHeading = styled.div`
  font-family: ${PROP_FONT_PRIMARY};
  color: #fff;
  margin-top: 0;
  margin-bottom: 15px;
  ::selection {
    color: none;
    background: none;
  }
  font-size: ${MOBILE.podcasts.slider.font.heading}px;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    &:hover {
      text-decoration: none;
      transform: scale(${LANDSCAPE.linkHover.scale}) translateX(65px);
      transition: all ${LANDSCAPE.linkHover.transition}s;
      color: ${LANDSCAPE.linkHover.color};
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.podcasts.slider.font.heading}px;
  }
`
export const PodcastSliderReadMore = styled.div`
  color: #fff;
  font-family: ${PROP_FONT_SECONDARY};
  font-style: italic;
  overflow: hidden;
  font-size: ${MOBILE.podcasts.slider.font.content}px;

  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.podcasts.slider.font.content}px;
  }

  & b {
    letter-spacing: 1.5px;
  }
`
export const PodcastSliderReadMoreSection = ({ string, offset }) => (
  <PodcastSliderReadMore>
    <ReadMorePara string={string} offset={offset} />
  </PodcastSliderReadMore>
)

export const PodcastListReadMore = styled.div`
  color: #fff;
  font-family: ${PROP_FONT_SECONDARY};
  overflow: hidden;
  width: 100%;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 5px;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.podcasts.list.font.content}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.podcasts.list.font.content}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.podcasts.list.font.content}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: ${LARGE.podcasts.list.font.content}px;
  }
  & b {
    letter-spacing: 1.5px;
  }
`
export const PodcastListReadMoreSection = ({ string, offset }) => (
  <PodcastListReadMore>
    <ReadMorePara string={string} offset={offset} />
  </PodcastListReadMore>
)
