import React, { useState, useContext, useEffect, useRef } from "react"
import { useMediaQuery } from "react-responsive"
import { useLazyQuery } from "@apollo/client"
import { Loader } from "rsuite"
import auth from "../../auth/auth"
import queryString from "query-string"
import { NotificationContainer } from "react-notifications"
import Transition from "../Transition"
import styled from "styled-components"
import { QUERY_ALL_USER_LIKES } from "../../queries/page/user"
import { QUERY_ALL_SETTINGS } from "../../queries/page/application"
import {
  QUERY_ALL_COUNTRIES,
  QUERY_ALL_COUNTRIES_BY_CRITERIA,
} from "../../queries/page/country"
import { getBasePath, COUNTRY_ALL } from "../../constants/props"
import { NotificationManager } from "react-notifications"
import jwtDecode from "jwt-decode"
import loadable from "@loadable/component"
import Header from "../Header"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
  DEVICE_MOBILE,
  DEVICE_LANDSCAPE,
  DEVICE_TAB,
  DEVICE_MEDIUM,
  DEVICE_LARGE,
} from "../../constants/breakpoints"

import { AppContext } from "../../contexts/AppContext"

const Body = styled.div`
  display: block;
  position: absolute;
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100%;
  transition: 300ms opacity ease-out;
  background: linear-gradient(rgb(0, 27, 50), rgb(0, 41, 59));
  height: 100%;
`

const BodyOverlay = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  top: 0px;
  bottom: 0px;
  background-color: rgb(0, 0, 0, 0.7);
  z-index: 10010;
`

const BodyLoader = styled(Loader).attrs({
  inverse: true,
  center: true,
})`
  position: fixed;
  display: block;
  width: 100%;
  top: 0px;
  bottom: 0px;
  background: linear-gradient(#001b32, #00293b);
  z-index: 9999;
  overflow: hidden;
  height: 100%;
  transition: 5s visibility ease-in;
`

// both header and body have same color and they meet at one point, a line was seen there hence reducing padding top by 0.1 vh below
const ViewWrapper = styled.div`
  margin-bottom: ${MOBILE.player.controls.bottom.height}px;
  flex-grow: 1;
  margin-top: ${MOBILE.header.height +
    MOBILE.header.subMenuFont +
    MOBILE.header.bottomMargin -
    0.2}vh;

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    margin-top: ${LANDSCAPE.header.height +
      LANDSCAPE.header.subMenuFont +
      LANDSCAPE.header.bottomMargin -
      0.1}vh;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: ${TAB.header.height +
      TAB.header.subMenuFont +
      TAB.header.bottomMargin -
      0.1}vh;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    margin-top: ${MEDIUM.header.height +
      MEDIUM.header.subMenuFont +
      MEDIUM.header.bottomMargin -
      0.1}vh;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    margin-top: ${LARGE.header.height +
      LARGE.header.subMenuFont +
      LARGE.header.bottomMargin -
      0.1}vh;
  }
`

const ContentSection = styled.div`
  color: #fff;
  flex-grow: 1;
  width: 100%;
  padding-top: 5px;
  background: linear-gradient(#001b32, #00293b);

  min-height: calc(
    ${100 - MOBILE.header.height - MOBILE.header.subMenuFont}vh -
      ${MOBILE.player.controls.bottom.height}px -
      ${MOBILE.header.bottomMargin}px
  );

  @media (max-width: ${MOBILE.maxWidth}px) {
    padding: ${MOBILE.websitePadding}px;
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    min-height: calc(
      ${100 - LANDSCAPE.header.height - LANDSCAPE.header.subMenuFont}vh -
        ${LANDSCAPE.player.controls.bottom.height}px -
        ${LANDSCAPE.header.bottomMargin}px
    );
  }

  @media (min-width: ${TAB.minWidth}px) {
    padding: ${TAB.websitePadding}px;
    padding-top: 10px;
    min-height: calc(
      ${100 - TAB.header.height - TAB.header.subMenuFont}vh -
        ${TAB.player.controls.bottom.height}px - ${TAB.header.bottomMargin}px
    );
  }

  @media (min-width: ${MEDIUM.minWidth}px) {
    padding: ${MEDIUM.websitePadding}px;
    min-height: calc(
      ${100 - MEDIUM.header.height - MEDIUM.header.subMenuFont}vh -
        ${MEDIUM.player.controls.bottom.height}px -
        ${MEDIUM.header.bottomMargin}px
    );
  }
  @media (min-width: ${LARGE.minWidth}px) {
    padding: ${LARGE.websitePadding}px;
    min-height: calc(
      ${100 - LARGE.header.height - LARGE.header.subMenuFont}vh -
        ${LARGE.player.controls.bottom.height}px -
        ${LARGE.header.bottomMargin}px
    );
  }
`
const EmbededPlayerWrapper = styled.div`
  min-height: 100px;
  width: 100%;
  display: flex;
  & div {
    margin: auto;
    position: inherit;
    justify-content: center;
  }
`

function useOutsideAlerter(ref, toggleHBMenu, setToggleHBMenu) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setToggleHBMenu(false)
    }
  }

  useEffect(() => {
    // Bind the event listener
    if (toggleHBMenu) {
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }
  })
}

const ReactPlayer = loadable(() => import("../ReactPlayer"))
const ContentEmbededPlayer = loadable(() =>
  import("../../components/ContentEmbededPlayer")
)
const Alert = loadable(() => import("../../utils/Alert"))
const Layout = ({ location, children }) => {
  const {
    menuState,
    userState,
    setUserState,
    setDevice,
    selectedCountry,
    setSelectedCountry,
    showLoader,
    setUserLikes,
    setCountries,
    setAppSettings,
    resetUserLoggedIn,
  } = useContext(AppContext)
  // const [toggleHBMenu, setToggleHBMenu] = useState(false)

  const search =
    location && location.search ? queryString.parse(location.search) : {}

  const pathComponents =
    location && location.pathname
      ? location.pathname.split("/").filter(p => p)
      : []

  let countrySlug = undefined
  if (pathComponents.length === 2) {
    switch (pathComponents[0]) {
      case "radios":
        countrySlug = pathComponents[1]
    }
  }

  let countryCriteria = countrySlug
    ? {
        where: {
          slug: countrySlug,
        },
      }
    : {
        where: {
          title: COUNTRY_ALL,
        },
      }

  const [toggleHBMenu, setToggleHBMenu] = useState(
    menuState === "" ? true : false
  )

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, toggleHBMenu, setToggleHBMenu)
  const [loaded, setLoaded] = useState(false)
  const [showPlayer, setShowPlayer] = useState(false)
  const [alertData, setAlertData] = useState({
    confirmed: false,
    shown: false,
  })

  // Media Queries //

  const isMobile = useMediaQuery({ maxWidth: MOBILE.maxWidth })
  const isLandscape = useMediaQuery({ minWidth: LANDSCAPE.minWidth })
  const isTab = useMediaQuery({ minWidth: TAB.minWidth })
  const isMedium = useMediaQuery({ minWidth: MEDIUM.minWidth })
  const isLarge = useMediaQuery({ minWidth: LARGE.minWidth })

  if (isLarge) {
    setDevice(true, DEVICE_LARGE)
  } else if (isMedium) {
    setDevice(true, DEVICE_MEDIUM)
  } else if (isTab) {
    setDevice(true, DEVICE_TAB)
  } else if (isLandscape) {
    setDevice(true, DEVICE_LANDSCAPE)
  } else if (isMobile) {
    setDevice(true, DEVICE_MOBILE)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setTimeout(() => {
      setLoaded(true)
    }, 500)

    setTimeout(() => {
      setShowPlayer(true)
    }, 5000)
  }, [])

  useEffect(() => {
    const userInfo = auth.getUserInfo()
    userInfo &&
      setUserState({
        ...userState,
        isLoggedIn: userInfo.confirmed ? true : false,
        confirmed: userInfo.confirmed,
        blocked: userInfo.blocked,
        role: userInfo.role,
        id: userInfo.id,
        firstname: userInfo.firstname,
        lastname: userInfo.lastname,
        username: userInfo.username,
        email: userInfo.email,
        provider: userInfo.provider,
        stationID: userInfo.stationID,
        station: userInfo.station,
        artist: userInfo.artist,
      })
  }, [])

  useEffect(() => {
    if (search && search.type === "confirmed" && !alertData.confirmed) {
      setAlertData({
        confirmed: true,
        shown: false,
      })
      setTimeout(() => {
        setAlertData({
          confirmed: true,
          shown: true,
        })
      }, 10000)
    }
  }, [alertData])

  const [getUserLikes] = useLazyQuery(QUERY_ALL_USER_LIKES, {
    context: {
      headers: {
        Authorization: "Bearer " + auth.getToken(),
      },
    },
    fetchPolicy: "network-only",
    onCompleted: userLikeData => {
      setUserLikes(userLikeData.userLikes)
    },
    onError: error => {
      // this generally happens when user token is invalid
      console.log("error.message is [" + error.message + "]")
      if (error && error.message && error.message.indexOf("token") > -1) {
        auth.clearToken()
        auth.clearUserInfo()
        resetUserLoggedIn()
        NotificationManager.success("Your session expired, please login again")
      }
    },
  })

  const [getSettings] = useLazyQuery(QUERY_ALL_SETTINGS, {
    fetchPolicy: "network-only",
    onCompleted: settingsData => {
      setAppSettings(
        settingsData && settingsData.applicationSettings
          ? settingsData.applicationSettings
          : []
      )
    },
  })

  const [getCountries] = useLazyQuery(QUERY_ALL_COUNTRIES, {
    variables: {
      order: "order:asc",
    },
    fetchPolicy: "network-only",
    onCompleted: countryData => {
      setCountries(countryData.countries)
      selectCountry({
        variables: countryCriteria,
      })
    },
  })

  const [selectCountry] = useLazyQuery(QUERY_ALL_COUNTRIES_BY_CRITERIA, {
    fetchPolicy: "network-only",
    onCompleted: selectedCountry => {
      if (
        selectedCountry &&
        selectedCountry.countries &&
        selectedCountry.countries.length
      ) {
        let c = selectedCountry.countries[0]
        setSelectedCountry({
          id: c.id,
          value: c.id,
          name: c.title,
          label: c.title,
          url: c && c.image && c.image.url ? c.image.url : "",
        })
      }
    },
  })

  useEffect(() => {
    getCountries()
  }, []) // empty list ensures that this runs only once

  useEffect(() => {
    if (
      countrySlug &&
      (!selectedCountry || selectedCountry.slug !== countrySlug)
    ) {
      selectCountry({
        variables: countryCriteria,
      })
    }
  }, [countrySlug])

  useEffect(() => {
    if (userState.id) {
      getUserLikes({
        variables: {
          userID: userState.id,
        },
      })
    }
  }, [userState])

  useEffect(() => {
    getSettings()
  }, [])

  useEffect(() => {
    if (auth.getToken()) {
      var decode = jwtDecode(auth.getToken())
      var tokenWillExpireIn = decode.exp * 1000 - Date.now()
      // Token will expire in next 5 minutes so logggin out user now only to that future operations do not create problems
      if (tokenWillExpireIn >= 0 && tokenWillExpireIn < 300 * 1000) {
        console.log(
          "Token will expire in next 5 minutes, so logging out now as a precaution"
        )
        auth.clearToken()
        auth.clearUserInfo()
        resetUserLoggedIn()
        NotificationManager.success("Your session expired, please login again")
      }
    }
  })

  return !location ? (
    <></>
  ) : location.pathname.indexOf("embededplayer") > -1 ? (
    <EmbededPlayerWrapper>
      {search.eid && (
        <ContentEmbededPlayer stationID={search.eid}></ContentEmbededPlayer>
      )}
    </EmbededPlayerWrapper>
  ) : (
    <React.Fragment>
      {!loaded && <BodyLoader />}
      <Body style={{ opacity: loaded ? "1" : "0" }}>
        {/* Load user likes as soon as user gets logged in */}
        {showLoader && (
          <div key="loader">
            <div className="loaderOuter">
              <div className="loaderInner">
                <img src={"/images/Loading.gif"} />
              </div>
            </div>
          </div>
        )}
        <NotificationContainer />
        {alertData.confirmed && (
          <Alert
            key="alert"
            variant="success"
            onClose={() =>
              setAlertData({
                confirmed: true,
                shown: true,
              })
            }
            show={alertData.confirmed && !alertData.shown}
            dismissible="true"
            style={{
              opacity: 1,
              position: "absolute",
              top: 10,
              left: 0,
              right: 0,
              textAlign: "center",
              zIndex: 11000,
            }}
          >
            You have successfully confirmed your email addresss, you can now
            login to your account.
          </Alert>
        )}
        {toggleHBMenu && <BodyOverlay />}
        {
          <React.Fragment>
            <Header location={location} onTriggerOverlay={() => {}} />
            <ViewWrapper>
              <ContentSection>
                <Transition location={location}>{children}</Transition>
              </ContentSection>
            </ViewWrapper>
            <ReactPlayer
              pathtype={location ? getBasePath(location.pathname) : undefined}
              queryParams={search}
            />
          </React.Fragment>
        }
      </Body>
    </React.Fragment>
  )
}

export default Layout
