// This will contain all the style components that are designed to be shared at multiple places
import styled from "styled-components"

import {
  APPSETTING_BOTTOM_AD_REFRESH,
  APPSETTING_BOTTOM_RIGHT_AD_REFRESH,
  APPSETTING_BOTTOM_LEFT_AD_REFRESH,
  APPSETTING_TOP_LEFT_AD_REFRESH,
  APPSETTING_TOP_RIGHT_AD_REFRESH,
} from "../../constants/props"

import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
  DEVICE_MOBILE,
} from "../../constants/breakpoints"
import { AppContext } from "../../contexts/AppContext"
import React, { useContext, useState, useEffect } from "react"

import IFrame from "react-iframe"

export const AdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${MOBILE.player.controls.bottom.height}vh;
  @media (min-width: ${TAB.minWidth}px) {
    margin-bottom: ${TAB.player.controls.bottom.height}px;
  }
`

export const LeftAd = styled.div`
  justify-content: flex-start;
  display: none;
  margin-bottom: 40px;
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.ads.sideAdsWidth}px;
    display: flex;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.ads.sideAdsWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.ads.sideAdsWidth}px;
  }
`
export const RightAd = styled.div`
  justify-content: flex-end;
  display: none;
  margin-bottom: 40px;
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.ads.sideAdsWidth}px;
    display: flex;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.ads.sideAdsWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.ads.sideAdsWidth}px;
  }
`
export const AdvertsWrapperFeaturedBottom = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
`
export const Ads728x90 = styled.div`
  background-repeat: no-repeat;
  background-image: url(${props => props.placeholder});
  background-size: contain;
  :after {
    content: ${props => props.advertIframe};
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.ads.bottomAdsWidth}vw;
    height: ${MOBILE.ads.bottomAdsHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.ads.bottomAdsWidth}vw;
    height: ${LANDSCAPE.ads.bottomAdsHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.ads.bottomAdsWidth}px;
    height: ${TAB.ads.bottomAdsHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.ads.bottomAdsWidth}px;
    height: ${MEDIUM.ads.bottomAdsHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.ads.bottomAdsWidth}px;
    height: ${LARGE.ads.bottomAdsHeight}px;
  }
`
export const Ads320x50 = styled.div`
  width: 320px;
  height: 50px;
  background-repeat: no-repeat;
  background-image: url(${props => props.placeholder});
  background-size: contain;
  :after {
    content: ${props => props.advertIframe};
  }
  overflow: hidden;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.ads.bottomAdsWidth}vw;
    height: ${MOBILE.ads.bottomAdsHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.ads.bottomAdsWidth}vw;
    height: ${LANDSCAPE.ads.bottomAdsHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.ads.bottomAdsWidth}px;
    height: ${TAB.ads.bottomAdsHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.ads.bottomAdsWidth}px;
    height: ${MEDIUM.ads.bottomAdsHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.ads.bottomAdsWidth}px;
    height: ${LARGE.ads.bottomAdsHeight}px;
  }
`

export const AdvertsWrapperVertical = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  text-align: center;
`

export const Ads120x600 = styled.div`
  right: 0;
  top: 0;
  width: 120px;
  height: 600px;
  align-self: flex-start;
  background-repeat: no-repeat;
  background-image: url(${props => props.placeholder});
  margin-top: 60px;
  :after {
    content: ${props => props.advertIframe};
  }
`
export const Ads160x600 = styled.div`
  width: 160px;
  height: 600px;
  background-repeat: no-repeat;
  background-image: url(${props => props.placeholder});
  margin-top: 60px;
  :after {
    content: ${props => props.advertIframe};
  }
`
export const LeftAdSection = () => {
  const { appSettings } = useContext(AppContext)
  const [adChange, setAdChange] = useState(0)
  useEffect(() => {
    const interval = setInterval(
      () => {
        setAdChange(adChange => adChange + 1)
      },
      appSettings && appSettings[APPSETTING_TOP_LEFT_AD_REFRESH]
        ? appSettings[APPSETTING_TOP_LEFT_AD_REFRESH] * 1000
        : 60000
    )
    return () => clearInterval(interval)
  }, [])

  return (
    <LeftAd>
      <script type="text/javascript" src="/schain.js"></script>
      <script data-adfscript="adx.adform.net/adx/?mid=1851236"></script>
      <script src="//s1.adform.net/banners/scripts/adx.js" async defer></script>
    </LeftAd>
  )
}

export const LeftAd2Section = () => {
  const { appSettings } = useContext(AppContext)
  const [adChange, setAdChange] = useState(0)
  useEffect(() => {
    const interval = setInterval(
      () => {
        setAdChange(adChange => adChange + 1)
      },
      appSettings && appSettings[APPSETTING_BOTTOM_LEFT_AD_REFRESH]
        ? appSettings[APPSETTING_BOTTOM_LEFT_AD_REFRESH] * 1000
        : 60000
    )
    return () => clearInterval(interval)
  }, [])

  return (
    <LeftAd>
      <script data-adfscript="adx.adform.net/adx/?mid=1851237"></script>
      <script src="//s1.adform.net/banners/scripts/adx.js" async defer></script>
    </LeftAd>
  )
}

export const RightAdSection = () => {
  const { appSettings } = useContext(AppContext)
  const [adChange, setAdChange] = useState(0)
  useEffect(() => {
    const interval = setInterval(
      () => {
        setAdChange(adChange => adChange + 1)
      },
      appSettings && appSettings[APPSETTING_TOP_RIGHT_AD_REFRESH]
        ? appSettings[APPSETTING_TOP_RIGHT_AD_REFRESH] * 1000
        : 60000
    )
    return () => clearInterval(interval)
  }, [])

  return (
    <RightAd>
      <script data-adfscript="adx.adform.net/adx/?mid=1851236"></script>
      <script src="//s1.adform.net/banners/scripts/adx.js" async defer></script>
    </RightAd>
  )
}

export const RightAd2Section = () => {
  const { appSettings } = useContext(AppContext)
  const [adChange, setAdChange] = useState(0)
  useEffect(() => {
    const interval = setInterval(
      () => {
        setAdChange(adChange => adChange + 1)
      },
      appSettings && appSettings[APPSETTING_BOTTOM_RIGHT_AD_REFRESH]
        ? appSettings[APPSETTING_BOTTOM_RIGHT_AD_REFRESH] * 1000
        : 60000
    )
    return () => clearInterval(interval)
  }, [])

  return (
    <RightAd>
      <script data-adfscript="adx.adform.net/adx/?mid=1851238"></script>
      <script src="//s1.adform.net/banners/scripts/adx.js" async defer></script>
    </RightAd>
  )
}

export const BottomAdSection = () => {
  const { device, appSettings } = useContext(AppContext)
  const [adChange, setAdChange] = useState(0)
  useEffect(() => {
    const interval = setInterval(
      () => {
        setAdChange(adChange => adChange + 1)
      },
      appSettings && appSettings[APPSETTING_BOTTOM_AD_REFRESH]
        ? appSettings[APPSETTING_BOTTOM_AD_REFRESH] * 1000
        : 60000
    )
    return () => clearInterval(interval)
  }, [])

  return (
    <AdvertsWrapperFeaturedBottom>
      {device === DEVICE_MOBILE ? (
        <IFrame
          id="ac3e5e47"
          name="ac3e5e47"
          frameBorder="0"
          scrolling="no"
          width="234"
          height="60"
          target="_blank"
          allow="autoplay"
          url={`https://servedby.revive-adserver.net/afr.php?zoneid=5632&cb=3059253&cn=${adChange}`}
        >
          <a
            href={`https://servedby.revive-adserver.net/ck.php?n=a18aa731&cb=3059253&cn=${adChange}`}
            target="_blank"
          >
            <img
              src={`https://servedby.revive-adserver.net/avw.php?zoneid=5632&cb=3059253&n=a18aa731&cn=${adChange}`}
              border="0"
              alt=""
            />
          </a>
        </IFrame>
      ) : (
        <AdWrapper>
          <script data-adfscript="adx.adform.net/adx/?mid=1851239"></script>
          <script src="//s1.adform.net/banners/scripts/adx.js" async defer></script>
        </AdWrapper>
      )}
    </AdvertsWrapperFeaturedBottom>
  )
}
