import React, { useContext, useState } from "react"
import { Link } from "gatsby"
import SearchBar from "./SearchBar"
import UserSection from "./UserSection"
import MainMenu from "../MainMenu"
import styled from "styled-components"
import UserMenus from "./UserMenus"
import { MenuLink, MenuLinkWrapper } from "../Styled"
import { navigate } from "gatsby-link"

import { COLOR_HEADER_BG } from "../../constants/colors"
import {
  convertToSlug,
  stationsPageURL,
  artistsPageURL,
} from "../../constants/props"

import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"
import { PROP_FONT_PRIMARY } from "../../constants/props"

import { AppContext } from "../../contexts/AppContext"

// import NoAvatarDisabled from "../../images/NoAvatarDisabled.svg"
// import NoAvatarEnabled from "../../images/NoAvatarEnabled.svg"

const Header = styled.div`
  position: fixed;
  background-color: ${COLOR_HEADER_BG};
  margin: 0;
  padding: 0;
  z-index: 10020;
  width: 100%;
  height: ${MOBILE.header.height +
    MOBILE.header.subMenuFont +
    MOBILE.header.bottomMargin}vh;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.header.height +
      LANDSCAPE.header.subMenuFont +
      MOBILE.header.bottomMargin}vh;
    z-index: 10000;
  }
  @media (min-width: ${TAB.minWidth}px) {
    z-index: 10020;
    height: ${TAB.header.height +
      TAB.header.subMenuFont +
      MOBILE.header.bottomMargin}vh;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    height: ${MEDIUM.header.height +
      MEDIUM.header.subMenuFont +
      MOBILE.header.bottomMargin}vh;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    height: ${LARGE.header.height +
      LARGE.header.subMenuFont +
      MOBILE.header.bottomMargin}vh;
  }
`

const SingleTopNavBar = styled.div`
  display: none;
  min-height: 7vh;
  padding: 5px;

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    display: flex;
  }
`

const SingeNavLeftPart = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const SingleNavRightPart = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const SingleNavMenuIcon = styled.div`
  color: #fff;
  margin-left: 15px;
  margin-right: 5px;
  & span {
    font-size: 30px;
    cursor: pointer;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    & span {
      font-size: 24px;
    }
    margin-left: 4px;
  }
`
const SingleLogoDiv = styled(Link)`
  flex: 0.8;
  display: none;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    display: flex;
  }
  align-self: center;
`
const SingleSocialIcons = styled.div`
  display: none;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    display: block;
    margin: auto 10px;
  }
`
const MultipleNavBarParent = styled.div`
  display: none;
  @media (max-width: ${MOBILE.maxWidth}px) {
    display: flex;
    flex-direction: column;
  }
`

const MultipleFirstNavBar = styled.div`
  display: flex;
  height: ${MOBILE.header.height}vh;
  align-items: center;
`

const MultipleSecondNavBar = styled.div`
  height: ${MOBILE.header.height}vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MultipleSecondNavBarGap = styled.div`
  flex: 0.3;
`

const LogoDivTopBar = styled(Link)`
  flex: 1;
  @media (max-width: ${MOBILE.maxWidth}px) {
    flex-grow: 0;
    padding: 5px;
  }
  display: flex;
  align-self: center;
`

const LogoImg = styled.img`
  margin: auto auto;
  height: ${MOBILE.header.logoHeight}vh;

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.header.logoHeight}vh;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.header.logoHeight}vh;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    height: ${MEDIUM.header.logoHeight}vh;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    height: ${LARGE.header.logoHeight}vh;
  }
`

const userData = {
  status: "guest",
  shortName: "Guest",
  firstName: "Guest",
  lastName: "Unknown",
  avatar: "",
}

const CountryDropDownContainer = styled.div`
  color: #fff;
  padding-top: 0px;
  font-family: ${PROP_FONT_PRIMARY};
  font-size: 18px;
  & select {
    cursor: pointer;
    margin-left: 5px;
    background: none;
    border: 0;
    border-bottom: 1px solid;
    outline: none;
    color: #fff;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0px 5px;
  }
  & option {
    color: #fff;
    background: #064072;
    font-size: 16px;
    outline: none;
  }
  display: flex;
  align-items: flex-start;

  @media (max-width: 850px) {
    flex-direction: column;
    & select {
      text-align-last: center;
    }
  }

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 12px;
    width: 100%;
    padding-left: 0px;
    flex-direction: column;
    & select {
      width: 54%;
      text-align-last: center;
    }
  }

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: 14px;
    margin-left: 2vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 16px;
    margin-left: 2vw;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 18px;
    padding-left: 8px;
    width: 100%;
  }
`

const MobileCountryDropDownContainer = styled(CountryDropDownContainer)`
  flex: 0.7;
  align-items: center;
`

const SocialIconsMenuBar = styled.div`
  display: block;
  margin: auto 10px;
`
const SocialIconsTopBar = styled.div`
  display: block;
  margin: auto 10px;
  flex: 1;
  text-align: right;
`
const SocialIcon = styled.div`
  display: inline-flex;
  & img {
    margin: 5px 5px;
    width: 25px;
  }
  & a {
    padding: 5px !important;
    &:hover {
      -webkit-text-decoration: none;
      text-decoration: none;
      -webkit-transform: scale(1.3) translateX(0px);
      -ms-transform: scale(1.3) translateX(0px);
      transform: scale(1.3) translateX(0px);
      -webkit-transition: all 0.25s;
      transition: all 0.25s;
    }
    @media (max-width: ${MOBILE.maxWidth}px) {
      padding: 0px 5px !important;
    }
  }
`
const MenuIconTopBar = styled.div`
  color: #fff;
  flex: 1;
  margin-left: 15px;
  margin-right: 5px;
  & span {
    font-size: 30px;
    cursor: pointer;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    & span {
      font-size: 24px;
      line-height: 1;
    }
    margin-left: 4px;
  }
`

const LPHeader = props => {
  const {
    countries,
    selectedCountry,
    setSelectedCountry,
    setShowLoader,
    device,
  } = useContext(AppContext)

  const onCountryChange = (event, location) => {
    // FIND COUNTRY IN COUNTRIES ARRAY WITH SELECTED ID AND EXPAND IT TO CREATE NEW OBJECT AND SET AS NEW SELECTED COUNTRY
    const selectedCountry = {
      ...countries.find(c => c.value === event.target.value),
    }
    setSelectedCountry(selectedCountry)

    // only navigate to media type when country is changed from home page

    if (location) {
      if (
        location.pathname === "/" ||
        location.pathname === "/mediatype" ||
        location.pathname === "/mediatype"
      ) {
        navigate(
          stationsPageURL(
            selectedCountry.id,
            convertToSlug(selectedCountry.name)
          )
        )
        setShowLoader(true)
        setTimeout(() => {
          setShowLoader(false)
        }, 1000)
      } else if (
        location.pathname === "/radios" ||
        location.pathname === "/radios/" ||
        location.pathname === "/radio" ||
        location.pathname === "/radio/"
      ) {
        navigate(
          stationsPageURL(
            selectedCountry.id,
            convertToSlug(selectedCountry.name)
          )
        )
      } else if (
        location.pathname === "/artists" ||
        location.pathname === "/artists/" ||
        location.pathname === "/artistdetails" ||
        location.pathname === "/artistdetails/"
      ) {
        navigate(
          artistsPageURL(
            selectedCountry.id,
            convertToSlug(selectedCountry.name)
          )
        )
      }
    }
  }

  const countryNameSlug =
    selectedCountry && selectedCountry.name
      ? convertToSlug(selectedCountry.name)
      : "all"
  const countryID =
    selectedCountry && selectedCountry.value ? selectedCountry.value : "all"
  const [navigationShow, setNavigationShow] = useState(false)

  return (
    <Header>
      <SingleTopNavBar>
        <SingeNavLeftPart>
          <SingleNavMenuIcon>
            <span onClick={() => setNavigationShow(true)}>&#9776;</span>
            <div
              id="mySidenav"
              className="sidenav"
              style={{ width: navigationShow ? "300px" : "0px" }}
            >
              <a className="closebtn" onClick={() => setNavigationShow(false)}>
                &times;
              </a>
              <UserSection {...userData} onUserDropdown={() => {}} />
              <MenuLinkWrapper>
                <MenuLink style={{ marginBottom: "30px", cursor: "auto" }}>
                  {/* GO PREMIUM TODAY ! */}
                </MenuLink>
              </MenuLinkWrapper>
              <MainMenu
                location={props.location}
                setNavigationShow={setNavigationShow}
              ></MainMenu>
              <UserMenus setNavigationShow={setNavigationShow} />
              <SocialIconsMenuBar>
                <SocialIcon>
                  <a
                    target="_new"
                    href="https://www.facebook.com/Stream-Africa-102425905000949"
                  >
                    {device ? (
                      <img
                        src={"/social/facebook.png"}
                        alt="streamafrica facebook"
                      />
                    ) : (
                      <img
                        src={"/social/facebook.png"}
                        alt="streamafrica facebook"
                        width={25}
                        height={25}
                      />
                    )}
                  </a>
                </SocialIcon>
                <SocialIcon>
                  <a target="_new" href="https://twitter.com/StreamInAfrica">
                    {device ? (
                      <img
                        src={"/social/Twitter.png"}
                        alt="streamafrica twitter"
                      />
                    ) : (
                      <img
                        src={"/social/Twitter.png"}
                        alt="streamafrica twitter"
                        width={25}
                        height={25}
                      />
                    )}
                  </a>
                </SocialIcon>
              </SocialIconsMenuBar>
            </div>
          </SingleNavMenuIcon>
          {/* props.location && props.location.pathname !== "/" &&
          <CountryDropDownContainer>
            <span>SELECTED COUNTRY:</span>
            <select
              onChange={event => {
                onCountryChange(event, props.location)
              }}
              value={
                selectedCountry && selectedCountry.value
                  ? selectedCountry.value
                  : "all"
              }
            >
              {countries &&
                countries.length &&
                countries.map(c => (
                  <option key={c.value} value={c.value}>
                    {c.name}
                  </option>
                ))}
            </select>
          </CountryDropDownContainer>
                */}
        </SingeNavLeftPart>
        <SingleLogoDiv to="/">
          {device ? (
            <LogoImg src={"/Logo.png"} />
          ) : (
            <LogoImg src={"/Logo.png"} width={54} height={43} />
          )}
        </SingleLogoDiv>
        <SingleNavRightPart>
          <SearchBar />
          <SingleSocialIcons>
            <SocialIcon>
              <a
                target="_new"
                href="https://www.facebook.com/Stream-Africa-102425905000949"
              >
                {device ? (
                  <img
                    src={"/social/facebook.png"}
                    alt="streamafrica facebook"
                  />
                ) : (
                  <img
                    src={"/social/facebook.png"}
                    alt="streamafrica facebook"
                    width={25}
                    height={25}
                  />
                )}
              </a>
            </SocialIcon>
            <SocialIcon>
              <a target="_new" href="https://twitter.com/StreamInAfrica">
                {device ? (
                  <img src={"/social/Twitter.png"} alt="streamafrica twitter" />
                ) : (
                  <img
                    src={"/social/Twitter.png"}
                    alt="streamafrica twitter"
                    width={25}
                    height={25}
                  />
                )}
              </a>
            </SocialIcon>
          </SingleSocialIcons>
        </SingleNavRightPart>
      </SingleTopNavBar>
      <MultipleNavBarParent>
        <MultipleFirstNavBar>
          <MenuIconTopBar>
            <span onClick={() => setNavigationShow(true)}>&#9776;</span>
            <div
              id="mySidenav"
              className="sidenav"
              style={{ width: navigationShow ? "300px" : "0px" }}
            >
              <a className="closebtn" onClick={() => setNavigationShow(false)}>
                &times;
              </a>
              <UserSection {...userData} onUserDropdown={() => {}} />
              <MenuLinkWrapper>
                <MenuLink
                  style={{ marginBottom: "30px", cursor: "auto" }}
                ></MenuLink>
              </MenuLinkWrapper>
              <MainMenu
                location={props.location}
                setNavigationShow={setNavigationShow}
              ></MainMenu>
              <UserMenus setNavigationShow={setNavigationShow} />
              <SocialIconsMenuBar>
                <SocialIcon>
                  <a
                    target="_new"
                    href="https://www.facebook.com/Stream-Africa-102425905000949"
                  >
                    {device ? (
                      <img
                        src={"/social/facebook.png"}
                        alt="streamafrica facebook"
                      />
                    ) : (
                      <img
                        src={"/social/facebook.png"}
                        alt="streamafrica facebook"
                        width={25}
                        height={25}
                      />
                    )}
                  </a>
                </SocialIcon>
                <SocialIcon>
                  <a target="_new" href="https://twitter.com/StreamInAfrica">
                    {device ? (
                      <img
                        src={"/social/Twitter.png"}
                        alt="streamafrica twitter"
                      />
                    ) : (
                      <img
                        src={"/social/Twitter.png"}
                        alt="streamafrica twitter"
                        width={25}
                        height={25}
                      />
                    )}
                  </a>
                </SocialIcon>
              </SocialIconsMenuBar>
            </div>
          </MenuIconTopBar>
          <LogoDivTopBar to="/">
            {device ? (
              <LogoImg src={"/Logo.png"} />
            ) : (
              <LogoImg src={"/Logo.png"} width={54} height={43} />
            )}
          </LogoDivTopBar>
          <SocialIconsTopBar>
            <SocialIcon>
              <a
                target="_new"
                href="https://www.facebook.com/Stream-Africa-102425905000949"
              >
                {device ? (
                  <img
                    src={"/social/facebook.png"}
                    alt="streamafrica facebook"
                  />
                ) : (
                  <img
                    src={"/social/facebook.png"}
                    alt="streamafrica facebook"
                    width={25}
                    height={25}
                  />
                )}
              </a>
            </SocialIcon>
            <SocialIcon>
              <a target="_new" href="https://twitter.com/StreamInAfrica">
                {device ? (
                  <img src={"/social/Twitter.png"} alt="streamafrica twitter" />
                ) : (
                  <img
                    src={"/social/Twitter.png"}
                    alt="streamafrica twitter"
                    width={25}
                    height={25}
                  />
                )}
              </a>
            </SocialIcon>
          </SocialIconsTopBar>
        </MultipleFirstNavBar>
        <MultipleSecondNavBar>
          {" "}
          {/*
          <MobileCountryDropDownContainer>
            <span>SELECTED COUNTRY:</span>
            <select
              onChange={event => {
                onCountryChange(event, props.location)
              }}
              value={
                selectedCountry && selectedCountry.value
                  ? selectedCountry.value
                  : "all"
              }
            >
              {countries &&
                countries.length &&
                countries.map(c => (
                  <option key={c.value} value={c.value}>
                    {c.name}
                  </option>
                ))}
            </select>
          </MobileCountryDropDownContainer>
                
          <MultipleSecondNavBarGap></MultipleSecondNavBarGap>
          */}
          <SearchBar />
        </MultipleSecondNavBar>
      </MultipleNavBarParent>
    </Header>
  )
}

export default LPHeader
