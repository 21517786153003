import styled from "styled-components"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"
import {
  PROP_FONT_PRIMARY
} from "../../constants/props"

export const MenuLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  text-decoration: none;
`
export const MenuLink = styled.div`
  padding-left: 20px;
  font-family: ${PROP_FONT_PRIMARY};
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${props => (props.active ? "black" : "#DEDEDD")};
  cursor: pointer;
  width: 100%;
  transition: 100ms all ease-in-out;
  margin-bottom: 5px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: 16px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: 16px;
    &:hover {
      text-decoration: none;
      transform: scale(${LANDSCAPE.linkHover.scale}) translateX(${LANDSCAPE.linkHover.translateX}px);
      transition: all ${LANDSCAPE.linkHover.transition}s;
      color: ${LANDSCAPE.linkHover.color};
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 18px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 18px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: 18px;
  }
`
