import React, { useContext, useState } from "react"
import styled from "styled-components"
import {
  PROP_MENU_LINK_LOAD_TIMEOUT,
} from "../../constants/props"
import {
  COLOR_MENU_BG,
  COLOR_MENU_TEXT
} from "../../constants/colors"
import { MOBILE, LANDSCAPE, TAB, MEDIUM, LARGE } from "../../constants/breakpoints"
import auth from "../../auth/auth"
import { AppContext } from "../../contexts/AppContext"
import { MenuLink, MenuLinkWrapper } from "../Styled"
import { navigate } from "gatsby"
import { NotificationManager } from "react-notifications"
import {
  PROP_FONT_PRIMARY
} from "../../constants/props"


const Menu = styled.div`
  background-color: ${COLOR_MENU_BG};
`

const ConfirmWrapper = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
`
const ConfirmSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 35px;
`
const MenuYesLink = styled.div`
  font-family: ${PROP_FONT_PRIMARY};
  margin: ${props => (props.ismobile ? "0.3vw 0" : "1.48vh 0")};
  color: ${COLOR_MENU_TEXT};
  cursor: pointer;
  transition: ${PROP_MENU_LINK_LOAD_TIMEOUT}ms all ease-in-out;
  @media (max-width: ${MOBILE.maxWidth}px) { 
    font-size: 22px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: 22px;
    &:hover {
      text-decoration: none;
      transform: scale(${LANDSCAPE.linkHover.scale}) translateX(0px);
      transition: all ${LANDSCAPE.linkHover.transition}s;
      color: ${LANDSCAPE.linkHover.color};
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 20px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 20px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: 20px;
  }
`
const MenuNoLink = styled.div`
  padding-left: 2vw;
  font-family: ${PROP_FONT_PRIMARY};
  margin: ${props => (props.ismobile ? "0.3vw 0" : "1.48vh 0")};
  color: ${COLOR_MENU_TEXT};
  cursor: pointer;
  transition: ${PROP_MENU_LINK_LOAD_TIMEOUT}ms all ease-in-out;
  @media (max-width: ${MOBILE.maxWidth}px) { 
    font-size: 22px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: 22px;
    &:hover {
      text-decoration: none;
      transform: scale(${LANDSCAPE.linkHover.scale}) translateX(0px);
      transition: all ${LANDSCAPE.linkHover.transition}s;
      color: ${LANDSCAPE.linkHover.color};
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: 20px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 20px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: 20px;
  }
`
const ConfirmMsg = styled.p`
  font-size: 14px;
  margin: 0;
` 

const UserMenus = props => {
  let { device, userState, resetUserLoggedIn, updatePlayer, ctxPlayer } = useContext(AppContext)

  const [logoutConfirmView, setLogoutConfirmView] = useState(false)

  const onSignoutYes = () => {
    setLogoutConfirmView(false)
    auth.clearToken()
    auth.clearUserInfo()
    // TODO: Think of logic to make it wait till signout
    resetUserLoggedIn()
    updatePlayer({
      ...ctxPlayer,
      type: null,
      id: null,
      title: null,
      streamuri: null,
      preview: null,
      subtitle: null,
      playlist: null,
      data: null,
      live: false,
      dataType: null,
      playing: false,
    })
    props.setNavigationShow(false)
    navigate("/")
    NotificationManager.success("Successfully logged out!")
  }

  const onSignoutNo = () => {
    setLogoutConfirmView(false)
  }

  return (
    <Menu ismobile={device === "mobile"}>
      {userState.isLoggedIn && (
        <MenuLinkWrapper onClick={() => props.setNavigationShow(true)}>
          <MenuLink onClick={() => setLogoutConfirmView(true)}>Logout</MenuLink>
        </MenuLinkWrapper>
      )}
      {logoutConfirmView && (
        <ConfirmSection>
          <ConfirmMsg>Are you sure ?</ConfirmMsg>
          <ConfirmWrapper>
            <MenuYesLink onClick={onSignoutYes}>Yes</MenuYesLink>
            <MenuNoLink onClick={onSignoutNo}>No</MenuNoLink>
          </ConfirmWrapper>
        </ConfirmSection>
      )}
    </Menu>
  )
}

export default UserMenus
