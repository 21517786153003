export const MOBILE = {
  maxWidth: 480,
  page: 100, // in vw
  contentWidth: 90, // in vw
  websitePadding: 0,
  // total header height would be = height + logoHeight + bottom margin (this would also become margin top of content)
  layout: {
    videoPlayerControls: 14, // in px
    paddingTop: 2, // in vh
  },
  header: {
    height: 8, // in vh
    logoHeight: 7, // in vh
    subMenuFont: 8, // font of sub menu also its height in header
    bottomMargin: 0, // Bottom margin of header
  },
  search: {
    height: 4, // in vh
    borderRadius: 2.4, // in vh
    font: 18,
    result: {
      top: 5.5, // in vh
      font: 16,
    },
  },
  player: {
    controls: {
      bottom: {
        height: 16, // in vh
        nextPrevHeight: 5, // in vw, height of next prev icons
        playHeight: 10, // in vw, height of play button
        volumeHeight: 5, // in vw, height of volume button
        titleFont: 14,
        subTitleFont: 10,
        timeFont: 12,
        volumeSliderHeight: 8, // in px
        volumeSliderWidth: 20, // in vw
      },
      maximized: {
        height: 10, // in vw
        imageHeight: 4, // width of player controls in vw
        imageMarginRight: 10, // right margin for controls on left, in px
        imageMarginLeft: 10, // left margin for controls on right, in px
        volumeSliderWidth: 17.4, // in vw
      },
      floating: {
        height: 14.53, // in vw
        imageHeight: 5, // width of player controls in vw
        closeImageHeight: 5, // width of player controls in vw
        imageMarginRight: 10, // right margin for controls on left, in px
        imageMarginLeft: 10, // left margin for controls on right, in px
        volumeSliderWidth: 25, // in vw
        titleFont: 12,
      },
    },
  },
  countrySlider: {
    heading: {
      paddingTop: 0,
      paddingBottom: 0,
      font: 20,
      subHeadingFont: 20,
      subTextFont: 11,
    },
    search: {
      width: 90, // in vw
      marginBottom: 0,
    },
    flag: {
      large: {
        layoutRow: 96,
        flagRow: 70, // in vw
        arrowWidth: 10, // in vw
        font: 22, // in px
        width: 65, // in vw
        height: 40, // in vw
        borderRadius: 20, // in px
      },
      small: {
        font: 16,
        flagRow: 96, // in vw
        padding: 4, // in vw
        width: 24, // in vw,
        height: 14, // in vw
        selectedWidthIncrease: 4, // should not be more than padding
        selectedHeightIncrease: 2.4, // should not be more than padding
        borderRadius: 5, // in px
      },
    },
  },
  box: {
    width: 96, // in vw
    height: 190,
    padding: 3, // in vw
    borderRadius: 30,
    anchorHeight: 20,
    carouselPaddingRight: 5, // in vw
    marginBottom: 10, // gap between heading and image/content section
    iconSize: 18, // like and share button in px
    arrowIconWidth: 25,
    font: {
      mainHeading: 20, // Font size of top heading
    },
    station: {
      featured: {
        contentGap: 3, // vw
        textOffset: 50,
        image: {
          widthHeight: 35,
          borderRadius: 10,
        },
        font: {
          title: 18,
          content: 14,
        },
      },
      details: {
        contentGap: 1, // vw
        textOffset: 80,
        font: {
          title: 18,
          content: 14,
        },
        image: {
          widthHeight: 35,
          borderRadius: 20,
        },
      },
    },
    artist: {
      featured: {
        contentGap: 3,
        textOffset: 50,
        image: {
          widthHeight: 35,
        },
        font: {
          title: 18,
          content: 16,
        },
      },
      details: {
        contentGap: 3,
        textOffset: 80,
        font: {
          title: 14,
          content: 12,
        },
        image: {
          widthHeight: 20,
          borderRadius: 20,
        },
        cover: {
          width: 40,
          height: 30,
        },
      },
    },
    podcasts: {
      image: {
        widthHeight: 35, // in vw
        borderRadius: 10,
      },
      font: {
        title: 18,
        content: 14,
      },
      contentGap: 3, // gap between image and content
      textSectionWidth: 49, // For smooth sliding experience of text in gray box we need to give fix width
      textOffset: 80, // Read more text characters to allow
    },
    videos: {
      featured: {
        textOffset: 50,
        font: {
          title: 18,
          content: 16,
        },
      },
      playing: {
        width: 96, // width of video when it is played
        height: 96 * 0.56, // height of video when it is played
        floatingWidth: 80,
        floatingHeight: 80 * 0.56,
      },
      image: {
        width: 90, // width of image in featured image box (videos page)
        height: 90 * 0.56, // height of image in featured image box (videos page)
      },
    },
  },
  contentSection: {
    // Content section (after box section)
    headingFont: 14,
  },
  stations: {
    grid: {
      paddingBottom: 3, // in vh
      cardRow: 100,
      cardWidth: 50,
      image: {
        widthHeight: 40,
        borderRadius: 10,
      },
      font: {
        title: 14,
        content: 12,
      },
    },
    slider: {
      slides: 2,
      cardRow: 74, // in vw - this is the row where card would fit (row would be extened with padding left/right when needed)
      paddingLeftRight: 10, // slider padding, this is the part that would be shown for next/previous
      textOffset: 30,
      textMarginTop: 2, // in vh - margin between image and text
      image: {
        widthHeight: 33, // in vw
        borderRadius: 10,
      },
      font: {
        heading: 24,
        title: 16,
        content: 14,
      },
    },
  },
  podcasts: {
    slider: {
      slides: 2,
      cardRow: 74, // in vw - this is the row where card would fit (row would be extened with padding left/right when needed)
      paddingLeftRight: 10, // slider padding, this is the part that would be shown for next/previous
      textOffset: 30,
      textMarginTop: 2, // in vh - margin between image and text
      image: {
        widthHeight: 33, // in vw
        borderRadius: 10,
      },
      font: {
        heading: 24,
        title: 16,
        content: 14,
      },
    },
    grid: {
      imageWidthHeight: 40, // in vw
    },
    list: {
      paddingTop: 20, // in px - padding top of cards
      paddingBottom: 20, // in px - padding bottom of cards
      contentGap: 3, // Space between image card and content,
      shareLikeIconSize: 14,
      textOffset: 80, // read more text offset
      font: {
        mainHeading: 18, // Slider heading and sort
        title: 16, // podcast title H3
        content: 14,
        date: 10, // podcast date span
      },
      image: {
        borderRadius: 10,
        widthHeight: 30,
      },
    },
  },
  videos: {
    slider: {
      textMarginTop: 2, // in vh - margin between text and videos
      textOffset: 120,
      slides: 1,
      cardRow: 74,
      paddingLeftRight: 10,
      image: {
        width: 70,
        height: 70 * 0.56,
      },
      font: {
        heading: 24,
        title: 14,
        content: 12,
      },
    },
    grid: {
      paddingBottom: 3, // in vw - bottom padding added to each card
      textMarginTop: 2, // in vh - margin between video & text
      cardRow: 90,
      cardWidth: 90,
      image: {
        width: 90,
        height: 90 * 0.56,
      },
      font: {
        title: 18,
        content: 16,
      },
    },
  },
  presenters: {
    slider: {
      slides: 2,
      cardRow: 64, // in vw
      paddingLeftRight: 16,
      cardWidth: 26, // in vw
      image: {
        widthHeight: 26,
      },
      font: {
        title: 14,
        content: 12,
      },
    },
  },
  artists: {
    grid: {
      contentGap: 3,
      textOffset: 80,
      image: {
        widthHeight: 35, // in vw
      },
      font: {
        title: 14,
        content: 12,
      },
    },
  },
  publicPages: {
    signin: {
      width: 90,
      font: {
        heading: 18,
      },
    },
    signup: {
      width: 90,
      font: {
        heading: 18,
      },
    },
    HeadingBottomMargin: 5,
    welcome: {
      font: {
        heading: 32,
        username: 20,
        email: 16,
        message: 14,
      },
    },
  },
  schedule: {
    dayFont: 16,
  },
  userdata: {
    headingFont: 18,
    topRightBtnFont: 14,
    formWidth: 75, // in vw
    headingPaddingBottom: 15,
  },
  ads: {
    bottomAdsWidth: 60,
    bottomAdsHeight: 60 * 0.15625,
  },
  social: {
    width: 40,
  },
}
