import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import axios from "axios"
import { navigate } from "gatsby-link"

import {
  COLOR_SEARCH_INPUT_TEXT,
  COLOR_HEADER_BG,
  COLOR_THEME_COLOR,
} from "../../../constants/colors"
import {
  PROP_CONTENT_TYPE_AUDIO_STATION,
  PROP_CONTENT_TYPE_ARTIST,
  convertToSlug,
  stationDetailsURL,
  artistDetailsURL,
  artistVideoDetailsPageURL,
  stationVideoDetailsPageURL,
  stationPodcastsPageURL,
  artistPodcastsPageURL,
  MEDIA_TYPE_AUDIO,
  MEDIA_TYPE_VIDEO,
} from "../../../constants/props"
import fetchResults from "./SearchResultProvider"
import Badge from "react-bootstrap/Badge"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../../constants/breakpoints"

import { AppContext } from "../../../contexts/AppContext"
import { PROP_FONT_PRIMARY } from "../../../constants/props"

const SearchSection = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: ${MOBILE.maxWidth}px) {
    flex: 0.9;
  }
  align-items: center;
  justify-content: flex-end;
`

const SearchInput = styled.input`
  border-bottom: 1px solid #fff;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding: 5px;
  color: #fff;
  outline: none;
  margin-right: 5px;
  background-color: ${COLOR_HEADER_BG};
  font-family: ${PROP_FONT_PRIMARY};
  ::placeholder {
    color: #fff;
    opacity: 0.5;
    font-size: ${MOBILE.search.font}px;
  }

  height: ${MOBILE.search.height}vh;
  border-radius: 0vh;
  width: 100%;

  font-size: ${MOBILE.search.font}px;

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.search.height}vh;
    border-radius: 0vh;
    font-size: ${LANDSCAPE.search.font}px;
    ::placeholder {
      font-size: ${LANDSCAPE.search.font}px;
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.search.height}px;
    border-radius: 0vh;
    font-size: ${TAB.search.font}px;
    ::placeholder {
      font-size: ${TAB.search.font}px;
    }
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    height: ${MEDIUM.search.height}px;
    border-radius: 0vh;
    font-size: ${MEDIUM.search.font}px;
    ::placeholder {
      font-size: ${MEDIUM.search.font}px;
    }
  }
  @media (min-width: ${LARGE.minWidth}px) {
    height: ${LARGE.search.height}px;
    border-radius: 0vh;
    font-size: ${LARGE.search.font}px;
    ::placeholder {
      font-size: ${LARGE.search.font}px;
    }
  }
`

const SearchResultTopNotch = styled.div`
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: -1.48vh;
  left: 50%;
  border-left: 1.48vh solid transparent;
  border-right: 1.48vh solid transparent;
  border-bottom: 1.48vh solid ${COLOR_THEME_COLOR};
  z-index: 10020;
`

const SearchResultDropdown = styled.div`
  position: absolute;
  display: block;
  top: ${MOBILE.header.height * 2}vh;
  min-height: auto;
  list-style: none;
  background-color: #064072;
  color: ${COLOR_SEARCH_INPUT_TEXT};
  box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.3);
  padding: 20px 10px;
  z-index: 10020;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.3);
  padding: 5px 3px;
  width: 80%;
  margin-right: 5px;

  @media (min-width: ${LANDSCAPE.minWidth}px) {
    top: ${LANDSCAPE.header.height}vh;
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.3);
    padding: 8px 4px;
    width: 40%;
  }
  @media (min-width: ${TAB.minWidth}px) {
    top: ${TAB.header.height}vh;
    box-shadow: 0px 3px 11px 3px rgba(0, 0, 0, 0.3);
    padding: 11px 5px;
    margin-right: 10px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    top: ${MEDIUM.header.height}vh;
    box-shadow: 0px 4px 14px 4px rgba(0, 0, 0, 0.3);
    padding: 14px 7px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    top: ${LARGE.header.height}vh;
    box-shadow: 0px 4px 14px 4px rgba(0, 0, 0, 0.3);
    padding: 14px 7px;
  }
`

const SearchResultItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: flex-start;
  font-family: ${PROP_FONT_PRIMARY};
  font-size: ${MOBILE.search.result.font}px;
  margin-bottom: 0px;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.search.result.font}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.search.result.font}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.search.result.font}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: ${LARGE.search.result.font}px;
  }
`

const SearchResultItem = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  cursor: pointer;
  height: ${MOBILE.search.result.font + 8}px;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    height: ${LANDSCAPE.search.result.font + 10}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    height: ${TAB.search.result.font + 12}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    height: ${MEDIUM.search.result.font + 14}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    height: ${LARGE.search.result.font + 16}px;
  }
`

const Title = styled.span`
  position: absolute;
  font-family: ${PROP_FONT_PRIMARY};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: left;
  width: 45%;
  vertical-align: middle;
  &:hover {
    text-decoration: none;
    transform: scale(${LANDSCAPE.linkHover.scale})
      translateX(${LANDSCAPE.linkHover.translateX}px);
    transition: all ${LANDSCAPE.linkHover.transition}s;
    color: ${LANDSCAPE.linkHover.color};
  }
  font-size: ${MOBILE.search.result.font}px;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.search.result.font}px;
    line-height: ${LANDSCAPE.search.result.font}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.search.result.font}px;
    line-height: ${TAB.search.result.font}px;
  }
`

const SearchIconImage = styled.img`
  margin: auto 1.46vw;
  width: 3.88vw;
  cursor: pointer;
  @media (min-width: ${TAB.minWidth}px) {
    width: 1.88vw;
  }
`

const SearchBar = () => {
  let { selectedCountry, device } = useContext(AppContext)
  const [state, setState] = useState({
    searchString: "",
    foundResults: [],
    filteredItems: [],
    data: [],
    showResults: false,
  })

  useEffect(() => {
    let source = axios.CancelToken.source()

    fetchResults({
      query: state.searchString,
      data: state,
      setData: setState,
      source: source, //To cancel prev request
    })

    return () => {
      source.cancel()
    }
  }, [state.searchString])

  const getFormattedResults = () => {
    const results = []
    const BadgeTag = {
      stations: { text: "Station", path: PROP_CONTENT_TYPE_AUDIO_STATION },
      artists: { text: "Artist", path: PROP_CONTENT_TYPE_ARTIST },
      mediacontents: { text: "", path: "" }, //Need to make dynamic path for Audio, Video
    }

    if (state.foundResults) {
      for (const [category, list] of Object.entries(state.foundResults)) {
        list.length &&
          results.push(
            list.map((field, key) => (
              <SearchResultItem
                key={key}
                onClick={() => {
                  switch (category) {
                    case "mediacontents":
                      if (field.contenttype === MEDIA_TYPE_AUDIO) {
                        // this would redirect to podcast page of station or artist
                        if (field.station && field.station.id) {
                          // this podcast belong to station
                          navigate(
                            stationPodcastsPageURL(
                              field.station.id,
                              convertToSlug(field.station.title),
                              selectedCountry.id,
                              convertToSlug(selectedCountry.name)
                            )
                          )
                        } else if (field.artist && field.artist.id) {
                          navigate(
                            artistPodcastsPageURL(
                              field.artist.id,
                              convertToSlug(field.artist.title),
                              selectedCountry ? selectedCountry.id : "",
                              selectedCountry
                                ? convertToSlug(selectedCountry.name)
                                : ""
                            )
                          )
                        }
                      } else if (field.contenttype === MEDIA_TYPE_VIDEO) {
                        // this would redirect to video details page page of station or artist
                        if (field.station && field.station.id) {
                          // this podcast belong to station
                          navigate(
                            stationVideoDetailsPageURL(
                              field.id,
                              convertToSlug(field.title),
                              field.station.id,
                              convertToSlug(field.station.title)
                            )
                          )
                        } else if (field.artist && field.artist.id) {
                          navigate(
                            artistVideoDetailsPageURL(
                              field.id,
                              convertToSlug(field.title),
                              field.artist.id,
                              convertToSlug(field.artist.title)
                            )
                          )
                        }
                      }
                      break
                    case "stations":
                      navigate(stationDetailsURL(field.slug))
                      break
                    case "artists":
                      navigate(
                        artistDetailsURL(
                          field.id,
                          convertToSlug(field.title),
                          selectedCountry ? selectedCountry.id : "",
                          selectedCountry
                            ? convertToSlug(selectedCountry.name)
                            : ""
                        )
                      )
                      break
                  }
                }}
              >
                <Title>
                  <Badge
                    variant="secondary"
                    style={{ display: "inline-block" }}
                  >
                    {(BadgeTag[category] && BadgeTag[category].text) ||
                      (field.contenttype === "audio" ? "Podcast" : "Video")}
                  </Badge>{" "}
                  {field.premium && <Badge variant="danger">P</Badge>}{" "}
                  {field.title}
                </Title>
              </SearchResultItem>
            ))
          )
      }
    }
    setState({
      ...state,
      filteredItems: results,
    })
    // return results
  }

  useEffect(() => {
    getFormattedResults()
  }, [state.foundResults])

  const onChangeHandler = e => {
    let value = e.target.value.trim()
    let searchValue = value.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "")

    if (searchValue.length < 3) {
      setState({ ...state, showResults: false })
      return
    } else {
      setState({ ...state, showResults: true, searchString: searchValue })
    }
  }

  const onSearchInputFocus = e => {
    setState({ ...state, filteredItems: [], searchString: "" })
  }

  let timeoutVar = null
  const onSearchInputBlur = e => {
    clearTimeout(timeoutVar)
    timeoutVar = setTimeout(() => {
      setState({ ...state, showResults: false })
    }, 200)
  }

  return (
    <SearchSection>
      {device ? (
        <SearchIconImage src={"/images/search.png"} alt="SearchIconImage" />
      ) : (
        <SearchIconImage
          src={"/images/search.png"}
          alt="SearchIconImage"
          height={14}
          width={14}
        />
      )}
      <SearchInput
        type="text"
        onChange={onChangeHandler}
        onFocus={onSearchInputFocus}
        onBlur={onSearchInputBlur}
        placeholder="Radio, Podcasts, Videos ..."
      />
      {state.showResults && (
        <SearchResultDropdown>
          <SearchResultTopNotch />
          <SearchResultItems>
            {state.filteredItems.length > 0
              ? state.filteredItems
              : "Nothing can be found"}
          </SearchResultItems>
        </SearchResultDropdown>
      )}
    </SearchSection>
  )
}

export default SearchBar
