import React, { useState } from "react"

import "./style.css"

const ReadMorePara = ({
  string,
  offset,
  labelMore,
  labelLess,
  fontFamily,
  fontColor,
}) => {
  if (!labelMore) labelMore = "Read more"
  if (!labelLess) labelLess = "Read less"
  if (!fontFamily) fontFamily = "inherit"
  if (!fontColor) fontColor = ""

  const [showMore, setShowMore] = useState({
    status: false,
    btnText: labelMore,
  })
  if (!string) return null
  
  if (string.length <= offset) return <p>{string}</p>

  const toggleShowMore = () => {
    setShowMore({
      status: !showMore.status,
      btnText: showMore.btnText === labelMore ? labelLess : labelMore,
    })
  }



  return (
    <span>
      {string.substring(0, offset)}
      {showMore.status ? (
        string.substring(offset, string.length)
      ) : (
        <>...</>
      )}
      <p
        className="para"
        style={{ fontFamily: fontFamily, color: fontColor, cursor: "pointer" }}
        onClick={() => toggleShowMore()}
      >
        {showMore.btnText}
      </p>
    </span>
  )
}

export default ReadMorePara
