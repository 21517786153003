// DEFAULT
// DEFAULT

import {
  DEVICE_MOBILE,
  DEVICE_TAB,
  DEVICE_MEDIUM,
  DEVICE_LARGE,
  DEVICE_LANDSCAPE,
  MOBILE,
  TAB,
  LANDSCAPE,
  MEDIUM,
  LARGE,
} from "./breakpoints"

import objectPath from "object-path"

export const WEB_DOMAIN = "http://beta.stream-africa.com"

export const DEVICE_DEFAULT_MIN_WIDTH = "1367px"
export const DEVICE_MID_LAPTOP_MAX_WIDTH = "1440px"
export const DEVICE_MID_LAPTOP_MIN_WIDTH = "1367px"

export const DEVICE_LAPTOP_MAX_WIDTH = "1366px"
export const DEVICE_LAPTOP_MIN_WIDTH = "1200px"

export const DEVICE_TABLET_MAX_WIDTH = "1199px"
export const DEVICE_TABLET_MIN_WIDTH = "960px"

export const DEVICE_SM_TABLET_MAX_WIDTH = "959px"
export const DEVICE_SM_TABLET_MIN_WIDTH = "768px"

export const DEVICE_MOBILE_MIN_WIDTH = "767px"
export const DEVICE_MOBILE_MAX_WIDTH = "480px"

export const PROP_HEADER_HEIGHT = "12.5vh"
export const PROP_MOBILE_HEADER_HEIGHT = "14.53vw"

export const PROP_LOGO_HEIGHT = "7.22vh"
export const PROP_LOGO_WIDTH = "4.9vw"

export const PROP_MENU_WIDTH = "22vw"
export const PROP_HEADER_USERINFO_WIDTH = "22vw"

export const PROP_AUDIO_PLAYER_HEIGHT = "75px"
export const PROP_AUDIO_MOBILE_PLAYER_HEIGHT = "14.53vw"
export const PROP_MENU_LINK_LOAD_TIMEOUT = 100

export const PROP_SEARCH_INPUT_PLACEHOLDER_TEXT =
  "Radio, Artists, Podcasts, Videos ..."

export const PROP_SEARCH_RESULT_DROPDOWN_WIDTH = "40%"
export const PROP_SEARCH_RESULT_TOP_NOTCH_TOP = "-1.48vh"
export const PROP_SEARCH_RESULT_TOP_NOTCH_LEFT = "0"
export const PROP_SEARCH_RESULT_TOP_NOTCH_BORDER = "1.48vh"

export const PROP_LIKE_SHARE_DROPDOWN_WIDTH = "20.83vw"

export const PROP_USER_PROFILE_DROPDOWN_RIGHT_PADDING = "2vw"
export const PROP_SIGNUP_DROPDOWN_WIDTH = "31.25vw"
export const PROP_SIGNIN_DROPDOWN_WIDTH = "22.91vw"
export const PROP_SIGNED_USER_DROPDOWN_WIDTH = "27.08vw"

export const PROP_SIGNINUP_FORM_BUTTON_HEIGHT = "4.26vh"
export const PROP_SIGNINUP_FORM_INPUT_FIELD_WIDTH = "16.67vw"
export const PROP_SIGNINUP_FORM_INPUT_FIELD_HEIGHT = "4.17vh"
export const PROP_SIGNINUP_FORM_INPUT_BORDER_THICKNESS = "1px"
export const PROP_SIGNINUP_FORM_INPUT_BORDER_RADIUS = "2.13vh"

export const PROP_SIGNINUP_FORM_MOBILE_BUTTON_HEIGHT = "7.8vw"
export const PROP_SIGNINUP_FORM_MOBILE_INPUT_FIELD_HEIGHT = "7.8vw"
export const PROP_SIGNINUP_FORM_MOBILE_INPUT_BORDER_RADIUS = "3.9vw"

export const PROP_NORMAL_FORM_BUTTON_HEIGHT = "4.26vh"
export const PROP_NORMAL_FORM_INPUT_FIELD_WIDTH = "100%"
export const PROP_NORMAL_FORM_INPUT_FIELD_HEIGHT = "4.17vh"
export const PROP_NORMAL_FORM_INPUT_BORDER_THICKNESS = "1px"
export const PROP_NORMAL_FORM_INPUT_BORDER_RADIUS = "0"

export const PROP_NORMAL_FORM_MOBILE_BUTTON_HEIGHT = "7.8vw"
export const PROP_NORMAL_FORM_MOBILE_INPUT_FIELD_HEIGHT = "7.8vw"
export const PROP_NORMAL_FORM_MOBILE_INPUT_BORDER_RADIUS = "0"

export const PROP_FONT_DEFAULT = "HelveticaNeue-Bold"
export const PROP_FONT_BC_ALPHAPINE_RB_BOLD = "BCAlphapipeRB-Bold"
export const PROP_FONT_HELVETICA_NEUE_BOLD = "HelveticaNeue-Bold"
export const PROP_FONT_HELVETICA_NEUE_LIGHT = "HelveticaNeue-Light"
export const PROP_FONT_HELVETICA_NEUE_LIGHTITALIC = "HelveticaNeue-LightItalic"
export const PROP_FONT_HELVETICA_NEUE_THIN = "HelveticaNeue-Thin"
export const PROP_FONT_NEXA_BOLD = "Nexa-Bold"
export const PROP_FONT_VOYAGE_FANTASTIQUE_STRAIGHT = "VoyageFantastiqueStra"
export const PROP_FONT_ZIPLINE_HALFSOLID = "Zipline-HalfSolid"
export const PROP_FONT_PRIMARY = "sairasemibold"
export const PROP_FONT_SECONDARY = "Saira"

/** CONTENT--TYPES */
export const PROP_CONTENT_TYPE_HOME = "home"
export const PROP_CONTENT_TYPE_AUDIO_COUNTRY = "audiocountry"
export const PROP_CONTENT_TYPE_AUDIO_COUNTRIES = "audiocountries"
export const PROP_CONTENT_TYPE_AUDIO_STATION = "audiostation"
export const PROP_CONTENT_TYPE_AUDIO_STATIONS = "audiostations"
export const PROP_CONTENT_TYPE_SHOW = "show"
export const PROP_CONTENT_TYPE_SHOWS = "shows"
export const PROP_CONTENT_TYPE_AUDIO_PODCAST = "audiopodcast"
export const PROP_CONTENT_TYPE_AUDIO_PODCASTS = "audiopodcasts"

/* New Nvigation Content Types */
export const PROP_CONTENT_TYPE_RADIO_DETAILS = "countryDetails"

export const PROP_CONTENT_TYPE_USER_AUDIO = "useraudio"
export const PROP_CONTENT_TYPE_USER_VIDEO = "uservideo"

export const PROP_CONTENT_TYPE_USER_STATION = "userstation"
export const PROP_CONTENT_TYPE_USER_AUDIOS = "useraudios"
export const PROP_CONTENT_TYPE_USER_VIDEOS = "uservideos"
export const PROP_CONTENT_TYPE_STATION_PRESENTERS = "stationpresenter"
export const PROP_CONTENT_TYPE_STATION_SHOWS = "stationshows"

export const PROP_CONTENT_TYPE_VIDEO_COUNTRY = "videocountry"
export const PROP_CONTENT_TYPE_VIDEO_COUNTRIES = "videocountries"
export const PROP_CONTENT_TYPE_VIDEO_STATION = "videostation"
export const PROP_CONTENT_TYPE_VIDEO_STATIONS = "videostations"
export const PROP_CONTENT_TYPE_YOUR_LIKES = "yourlikes"
export const PROP_CONTENT_TYPE_ABOUT = "about"
export const PROP_CONTENT_LOGIN = "login"
export const PROP_CONTENT_TYPE_ARTIST = "artist"
export const PROP_CONTENT_TYPE_ARTISTS = "artists"
export const PROP_CONTENT_TYPE_PRESENTER = "presenter"
export const PROP_CONTENT_TYPE_PRESENTERS = "presenters"
export const PROP_CONTENT_TYPE_ARTIST_PRESENTER = "artistpresenter"

export const MEDIA_TYPE_IMAGE = "image"
export const MEDIA_TYPE_AUDIO = "audio"
export const MEDIA_TYPE_VIDEO = "video"
export const CARD_TYPE_PRESENTER = "presenter"

export const VIDEO_MODE_CLOSED = "closed"
export const VIDEO_MODE_MAXIMIZED = "maximized"
export const VIDEO_MODE_MINIMIZED = "minimized"
export const VIDEO_MODE_FULLSCREEN = "fullscreen"

export const ROLE_LISTENER = "listener"
export const ROLE_STATIONADMIN = "stationadmin"
export const ROLE_ARTIST = "artist"

export const AWS_STATIONS = "stations"
export const AWS_IMAGES = "images"
export const AWS_VIDEOS = "videos"
export const AWS_PODCASTS = "podcasts"
export const AWS_PRESENTERS = "presenters"
export const AWS_SHOWS = "shows"
export const AWS_ARTISTS = "artists"
export const COUNTRY_ALL = "All"

export const DATA_TYPE_STATION = "Station"
export const DATA_TYPE_ARTIST = "Artist"
export const DATA_TYPE_STATION_VIDEO = "StationVideo"
export const DATA_TYPE_STATION_PODCAST = "StationPodcast"
export const DATA_TYPE_ARTIST_VIDEO = "ArtistVideo"
export const DATA_TYPE_ARTIST_PODCAST = "ArtistPodcast"

export const APPSETTING_TOP_LEFT_AD_REFRESH = "top_left_ad_refresh_seconds"
export const APPSETTING_TOP_RIGHT_AD_REFRESH = "top_right_ad_refresh_seconds"
export const APPSETTING_BOTTOM_LEFT_AD_REFRESH =
  "bottom_left_ad_refresh_seconds"
export const APPSETTING_BOTTOM_RIGHT_AD_REFRESH =
  "bottom_left_ad_refresh_seconds"
export const APPSETTING_BOTTOM_AD_REFRESH = "bottom_ad_refresh_seconds"

export const APPSETTING_VIEW_COUNT_DELAY = "views_count_delay"
export const APPSETTING_USER_DATA_DAYS = "user_data_days"

export const awsStationImagesPath = stationTitle => {
  return `${AWS_STATIONS}/${convertToSlug(stationTitle)}/${AWS_IMAGES}`
}

export const awsStationPodcastsPath = stationTitle => {
  return `${AWS_STATIONS}/${convertToSlug(stationTitle)}/${AWS_PODCASTS}`
}

export const awsStationVideosPath = stationTitle => {
  return `${AWS_STATIONS}/${convertToSlug(stationTitle)}/${AWS_VIDEOS}`
}

export const awsStationPresenterPath = (stationTitle, presenterName) => {
  return `${AWS_STATIONS}/${convertToSlug(
    stationTitle
  )}/${AWS_PRESENTERS}/${convertToSlug(presenterName)}`
}

export const awsStationShowsPath = (stationTitle, showTitle) => {
  return `${AWS_STATIONS}/${convertToSlug(
    stationTitle
  )}/${AWS_SHOWS}/${convertToSlug(showTitle)}`
}

export const awsArtistVideosPath = artistName => {
  return `${AWS_ARTISTS}/${convertToSlug(artistName)}/${AWS_VIDEOS}`
}

export const awsArtistPodcastsPath = artistName => {
  return `${AWS_ARTISTS}/${convertToSlug(artistName)}/${AWS_PODCASTS}`
}

export const awsArtistImagesPath = artistName => {
  return `${AWS_ARTISTS}/${convertToSlug(artistName)}/${AWS_IMAGES}`
}

/*
Each link type would have some associated properties that would be used in various places for rendering.
This is like configuration for each type of link that user can navigate in this whole application.
- menuText = Used to show menu text in side menu
- linkAddr = User would navigate to this address when user clicks link of chosen type

*/

export const getLinkTypeData = (type, data) => {
  switch (type) {
    case PROP_CONTENT_TYPE_AUDIO_COUNTRIES:
      return {
        menuText: "Countries",
        linkAddr: PROP_CONTENT_TYPE_AUDIO_COUNTRIES,
      }
    case PROP_CONTENT_TYPE_AUDIO_COUNTRY:
      return {
        linkAddr: PROP_CONTENT_TYPE_AUDIO_STATIONS,
      }
    case PROP_CONTENT_TYPE_AUDIO_STATIONS:
      return {
        menuText: "Stations",
        linkAddr: PROP_CONTENT_TYPE_AUDIO_STATIONS,
      }
    case PROP_CONTENT_TYPE_AUDIO_PODCAST:
      return {
        linkAddr: PROP_CONTENT_TYPE_AUDIO_STATION,
      }
    case PROP_CONTENT_TYPE_AUDIO_STATION:
      return {
        linkAddr: PROP_CONTENT_TYPE_AUDIO_STATION,
      }
    case PROP_CONTENT_TYPE_AUDIO_PODCASTS:
      return {
        menuText: "Podcasts",
        linkAddr: PROP_CONTENT_TYPE_AUDIO_PODCASTS,
      }
    case PROP_CONTENT_TYPE_ARTIST:
      return {
        linkAddr: PROP_CONTENT_TYPE_ARTIST,
      }
    case PROP_CONTENT_TYPE_PRESENTER:
      return {
        linkAddr: PROP_CONTENT_TYPE_ARTIST_PRESENTER,
      }
    case PROP_CONTENT_TYPE_VIDEO_COUNTRIES:
      return {
        menuText: "Countries",
        linkAddr: PROP_CONTENT_TYPE_VIDEO_COUNTRIES,
      }
    case PROP_CONTENT_TYPE_VIDEO_STATIONS:
      return {
        menuText: "Stations",
        linkAddr: PROP_CONTENT_TYPE_VIDEO_STATIONS,
      }
    case PROP_CONTENT_TYPE_VIDEO_COUNTRY:
      return {
        linkAddr: PROP_CONTENT_TYPE_VIDEO_COUNTRY,
      }
    case PROP_CONTENT_TYPE_VIDEO_STATION:
      return {
        linkAddr: PROP_CONTENT_TYPE_VIDEO_STATION,
      }
    case PROP_CONTENT_TYPE_RADIO_DETAILS:
      return {
        menuText: "RADIO",
        linkAddr: PROP_CONTENT_TYPE_RADIO_DETAILS,
      }
    case PROP_CONTENT_TYPE_YOUR_LIKES:
      return {
        menuText: "Your likes",
        linkAddr: PROP_CONTENT_TYPE_AUDIO_COUNTRIES,
      }
    case PROP_CONTENT_TYPE_ABOUT:
      return {
        menuText: "About Us",
        linkAddr: PROP_CONTENT_TYPE_ABOUT,
      }
    case PROP_CONTENT_LOGIN:
      return {
        menuText: "Sign in",
        linkAddr: PROP_CONTENT_LOGIN,
      }
    case PROP_CONTENT_TYPE_USER_AUDIOS:
      return {
        linkAddr: PROP_CONTENT_TYPE_USER_AUDIOS,
      }
    case PROP_CONTENT_TYPE_USER_VIDEOS:
      return {
        linkAddr: PROP_CONTENT_TYPE_USER_VIDEOS,
      }
    case PROP_CONTENT_TYPE_USER_STATION:
      return {
        linkAddr: PROP_CONTENT_TYPE_USER_STATION,
      }
    case PROP_CONTENT_TYPE_STATION_PRESENTERS:
      return {
        menuText: "Presenters",
        linkAddr: PROP_CONTENT_TYPE_STATION_PRESENTERS,
      }
    case PROP_CONTENT_TYPE_STATION_SHOWS:
      return {
        menuText: "Shows",
        linkAddr: PROP_CONTENT_TYPE_STATION_SHOWS,
      }
    default:
      return undefined
  }
}

// returns first part of path which is generally used to identify which page is rendered
export const getBasePath = path => {
  let basePath = undefined
  if (path) {
    if (path.length > 1) {
      let idx = path.indexOf("/", 1)
      basePath = idx > 0 ? path.substring(1, idx) : path.substring(1)
    } else basePath = "/" // path is route path
  }
  return basePath
}

/*
When user navigate directly to some URL without selecting any of the links in system, like when they enter certain url in 
browser, or click on share url or hit back button. Get link type would help in getting type associated with that path
and perform certain tasks accordingly like showing that particular menu as selected in side menu or perform actions 
like minimizing a paused video
*/

export const getLinkType = path => {
  let basePath = getBasePath(path)

  if (basePath) {
    switch (basePath) {
      case "/":
        return PROP_CONTENT_TYPE_HOME
      case "audiocountries":
        return PROP_CONTENT_TYPE_AUDIO_COUNTRIES
      case "audiostations":
        return PROP_CONTENT_TYPE_AUDIO_STATIONS
      case "videocountries":
        return PROP_CONTENT_TYPE_VIDEO_COUNTRIES
      case "videostations":
        return PROP_CONTENT_TYPE_VIDEO_STATIONS
      case "audiopodcasts":
        return PROP_CONTENT_TYPE_AUDIO_PODCASTS
      case "about":
        return PROP_CONTENT_TYPE_ABOUT
      case "login":
        return PROP_CONTENT_LOGIN
      case "useraudios":
        return PROP_CONTENT_TYPE_USER_AUDIOS
      case "uservideos":
        return PROP_CONTENT_TYPE_USER_VIDEOS
      case "userstation":
        return PROP_CONTENT_TYPE_USER_STATION
      case "stationpresenter":
        return PROP_CONTENT_TYPE_STATION_PRESENTERS
      case "stationshows":
        return PROP_CONTENT_TYPE_STATION_SHOWS
      default:
        return undefined
    }
  }
  return undefined
}

export const convertToSlug = Text => {
  if (!Text) return ""
  return Text.toLowerCase()
    .replace(/[^\w ]+/g, "")
    .replace(/ +/g, "-")
}

export const objectToQueryString = obj =>
  Object.keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&")

export const stationsPageURL = (countryID, countrySlug) => {
  return `/stations?__cid=${countryID}&c=${countrySlug}`
}
export const artistsPageURL = (countryID, countrySlug) => {
  return `/artists?__cid=${countryID}&c=${countrySlug}`
}
export const mediaTypePageURL = (countryID, countrySlug) => {
  return `/mediatype?__cid=${countryID}&c=${countrySlug}`
}

export const stationDetailsURL = stationSlug => {
  return `/radio/${stationSlug}`
}
export const stationPodcastsPageURL = (
  stationID = "",
  stationNameSlug = "",
  countryID = "",
  countrySlug = "",
  imageUrl = ""
) => {
  return `/stationpodcasts?s=${stationNameSlug}&c=${countrySlug}&__cid=${countryID}&__sid=${stationID}&i=${imageUrl}`
}
export const stationVideosPageURL = (
  stationID = "",
  stationNameSlug = "",
  countryID = "",
  countrySlug = "",
  imageUrl = ""
) => {
  return `/stationvideos?s=${stationNameSlug}&c=${countrySlug}&__cid=${countryID}&__sid=${stationID}&i=${imageUrl}`
}
export const stationVideoDetailsPageURL = (
  videoID,
  videoSlug,
  stationID,
  stationSlug
) => {
  return `/stationvideodetails?v=${videoSlug}&s=${stationSlug}&__sid=${stationID}&__vid=${videoID}`
}
export const artistDetailsURL = (
  artistID,
  artistSlug,
  countryID,
  countrySlug
) => {
  return `/artistdetails?a=${artistSlug}&c=${countrySlug}&__aid=${artistID}&__cid=${countryID}`
}

export const artistPodcastsPageURL = (
  artistID,
  artistNameSlug,
  countryID,
  countryNameSlug
) => {
  return `/artistpodcasts?a=${artistNameSlug}&c=${countryNameSlug}&__cid=${countryID}&__aid=${artistID}`
}
export const artistVideosPageURL = (
  artistID,
  artistNameSlug,
  countryID,
  countryNameSlug
) => {
  return `/artistvideos?a=${artistNameSlug}&c=${countryNameSlug}&__cid=${countryID}&__aid=${artistID}`
}
export const artistVideoDetailsPageURL = (
  videoID,
  videoSlug,
  artistID,
  artistSlug
) => {
  return `/artistvideodetails?v=${videoSlug}&a=${artistSlug}&__aid=${artistID}&__vid=${videoID}`
}

export const userStationURL = () => {
  return "/userstation"
}

export const userStationPresentersURL = () => {
  return "/stationpresenter"
}

export const userStationShowsURL = () => {
  return "/stationshows"
}

export const userArtistURL = () => {
  return "/userartist"
}

export const userPodcastsURL = () => {
  return `/userpodcasts`
}

export const userVideosURL = () => {
  return `/uservideos`
}

export const userLikesURL = () => {
  return `/yourlikes`
}

export const userLoginURL = () => {
  return "/login"
}

export const getBreakpointObject = device => {
  switch (device) {
    case DEVICE_MOBILE:
      return MOBILE
    case DEVICE_LANDSCAPE:
      return LANDSCAPE
    case DEVICE_TAB:
      return TAB
    case DEVICE_MEDIUM:
      return MEDIUM
    case DEVICE_LARGE:
      return LARGE
    default:
      return null
  }
}

export const getConfigValue = (device, path) => {
  return objectPath.get(getBreakpointObject(device), path)
}

export const getEpisodeDayNumber = day => {
  switch (day) {
    case "Monday":
      return 1
    case "Tuesday":
      return 2
    case "Wednesday":
      return 3
    case "Thursday":
      return 4
    case "Friday":
      return 5
    case "Saturday":
      return 6
    default:
      return 0
  }
}
