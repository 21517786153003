// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artistdetails-js": () => import("./../src/pages/artistdetails.js" /* webpackChunkName: "component---src-pages-artistdetails-js" */),
  "component---src-pages-artistpodcasts-js": () => import("./../src/pages/artistpodcasts.js" /* webpackChunkName: "component---src-pages-artistpodcasts-js" */),
  "component---src-pages-artists-js": () => import("./../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-artistvideodetails-js": () => import("./../src/pages/artistvideodetails.js" /* webpackChunkName: "component---src-pages-artistvideodetails-js" */),
  "component---src-pages-artistvideos-js": () => import("./../src/pages/artistvideos.js" /* webpackChunkName: "component---src-pages-artistvideos-js" */),
  "component---src-pages-country-stations-template-js": () => import("./../src/pages/country-stations-template.js" /* webpackChunkName: "component---src-pages-country-stations-template-js" */),
  "component---src-pages-embededplayer-js": () => import("./../src/pages/embededplayer.js" /* webpackChunkName: "component---src-pages-embededplayer-js" */),
  "component---src-pages-forgotpassword-js": () => import("./../src/pages/forgotpassword.js" /* webpackChunkName: "component---src-pages-forgotpassword-js" */),
  "component---src-pages-general-js": () => import("./../src/pages/general.js" /* webpackChunkName: "component---src-pages-general-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mediatype-js": () => import("./../src/pages/mediatype.js" /* webpackChunkName: "component---src-pages-mediatype-js" */),
  "component---src-pages-perf-js": () => import("./../src/pages/perf.js" /* webpackChunkName: "component---src-pages-perf-js" */),
  "component---src-pages-register-js": () => import("./../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-resendconfirmlink-js": () => import("./../src/pages/resendconfirmlink.js" /* webpackChunkName: "component---src-pages-resendconfirmlink-js" */),
  "component---src-pages-resetpassword-js": () => import("./../src/pages/resetpassword.js" /* webpackChunkName: "component---src-pages-resetpassword-js" */),
  "component---src-pages-station-details-template-js": () => import("./../src/pages/station-details-template.js" /* webpackChunkName: "component---src-pages-station-details-template-js" */),
  "component---src-pages-stationpodcasts-js": () => import("./../src/pages/stationpodcasts.js" /* webpackChunkName: "component---src-pages-stationpodcasts-js" */),
  "component---src-pages-stationpresenter-js": () => import("./../src/pages/stationpresenter.js" /* webpackChunkName: "component---src-pages-stationpresenter-js" */),
  "component---src-pages-stationshows-js": () => import("./../src/pages/stationshows.js" /* webpackChunkName: "component---src-pages-stationshows-js" */),
  "component---src-pages-stationvideodetails-js": () => import("./../src/pages/stationvideodetails.js" /* webpackChunkName: "component---src-pages-stationvideodetails-js" */),
  "component---src-pages-stationvideos-js": () => import("./../src/pages/stationvideos.js" /* webpackChunkName: "component---src-pages-stationvideos-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-userartist-js": () => import("./../src/pages/userartist.js" /* webpackChunkName: "component---src-pages-userartist-js" */),
  "component---src-pages-userpodcasts-js": () => import("./../src/pages/userpodcasts.js" /* webpackChunkName: "component---src-pages-userpodcasts-js" */),
  "component---src-pages-userstation-js": () => import("./../src/pages/userstation.js" /* webpackChunkName: "component---src-pages-userstation-js" */),
  "component---src-pages-uservideos-js": () => import("./../src/pages/uservideos.js" /* webpackChunkName: "component---src-pages-uservideos-js" */),
  "component---src-pages-welcomeuser-js": () => import("./../src/pages/welcomeuser.js" /* webpackChunkName: "component---src-pages-welcomeuser-js" */),
  "component---src-pages-yourlikes-js": () => import("./../src/pages/yourlikes.js" /* webpackChunkName: "component---src-pages-yourlikes-js" */),
  "component---src-templates-country-stations-js": () => import("./../src/templates/CountryStations.js" /* webpackChunkName: "component---src-templates-country-stations-js" */),
  "component---src-templates-home-js": () => import("./../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-station-details-js": () => import("./../src/templates/StationDetails.js" /* webpackChunkName: "component---src-templates-station-details-js" */)
}

