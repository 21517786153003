export const LANDSCAPE = {
  minWidth: 481,
  page: 100, // in vw
  contentWidth: 60, // in vw
  websitePadding: 20,
  linkHover: {
    color: '#ccc',
    scale: 1.15,
    translateX: 15, //in px
    transition: 0.25, // in seconds 
  },
  layout: {
    paddingTop: 2, // in vh
    playPauseButton: 35, // in px
    trackArrowButton: 16, //in px
    volumeButton: 25, //in px
    videoPlayerControls: 14, // in px
  },
  header: {
    height: 14, // in vh
    logoHeight: 10, // in vh
    subMenuFont: 1, // font of sub menu also its height in header in vh
    bottomMargin: 0, // Bottom margin of header in vh
  },
  search: {
    height: 4, // in vh
    borderRadius: 3.4,
    font: 9,
    result: {
      top: 8.5, // in vh
      font: 10,
    },
  },
  player: {
    controls: {
      bottom: {
        height: 50, // in px
        nextPrevHeight: 15, // in px, height of next prev icons
        playHeight: 30, // in px, height of play button
        volumeHeight: 15, // in px, height of volume mute button
        subTitleWidth: 10, // in px
        titleFont: 16,
        subTitleFont: 12,
        timeFont: 12,
      },
      maximized: {
        height: 5, // in vw
        imageHeight: 2, // width of player controls
        closeImageHeight: 2, // width of close button in vw
        imageMarginRight: 15, // right margin for controls on left
        imageMarginLeft: 15, // left margin for controls on right
        volumeSliderWidth: 17.4, // in vw
      },
      floating: {
        height: 4, // in vw
        imageHeight: 2, // width of player controls
        imageMarginRight: 10, // right margin for controls on left
        imageMarginLeft: 10, // left margin for controls on right
        volumeSliderWidth: 17.4, // in vw
      },
    },
  },
  countrySlider: {
    heading: {
      paddingTop: 0,
      paddingBottom: 0,
      font: 20,
      subHeadingFont: 20,
      subTextFont: 11,
    },
    search: {
      width: 60, // in vw
      marginBottom: 15,
    },
    flag: {
      large: {
        layoutRow: 60,
        flagRow: 40, // in vw
        arrowWidth: 5, // in vw
        font: 20, // in px
        width: 30, // in vw
        height: 30 * 0.6, // in vw
        borderRadius: 20, // in px
      },
      small: {
        font: 16,
        flagRow: 88, // in vw
        padding: 2,
        width: 18, // in vw,
        height: 18 * 0.6, // in vw
        selectedWidthIncrease: 2, // should not be more than padding
        selectedHeightIncrease: 1.2, // should not be more than padding
        borderRadius: 5, // in px
      },
    },
  },
  box: {
    width: 70, // in vw
    height: 190,
    padding: 10,
    borderRadius: 30,
    anchorHeight: 20,
    carouselPaddingRight: 3, // in vw
    marginBottom: 10, // gap between heading and image/content section
    iconSize: 18, // like and share button in px
    arrowIconWidth: 25,
    font: {
      mainHeading: 20, // Font size of top heading
      title: 14,
      content: 12,
    },
    station: {
      featured: {
        contentGap: 3, // vw
        textOffset: 100,
        image: {
          widthHeight: 24,
          borderRadius: 10,
        },
      },
      details: {
        contentGap: 3,
        textOffset: 100,
        font: {
          title: 14,
          content: 12,
        },
        image: {
          widthHeight: 20,
          borderRadius: 20,
        },
      },
    },
    artist: {
      featured: {
        textOffset: 80,
        contentGap: 1,
        image: {
          widthHeight: 20,
        },
      },
      details: {
        contentGap: 3,
        textOffset: 100,
        font: {
          title: 14,
          content: 12,
        },
        image: {
          widthHeight: 20,
          borderRadius: 20,
        },
        cover: {
          width: 30,
          height: 20,
        },
      },
    },

    podcasts: {
      image: {
        widthHeight: 24, // in vw
        borderRadius: 10,
      },
      font: {
        title: 14,
        content: 12,
      },
      contentGap: 3, // in vw, gap between image and content
      textSectionWidth: 49, // For smooth sliding experience of text in gray box we need to give fix width
      textOffset: 80, // Read more text characters to allow
    },
    videos: {
      featured: {
        textOffset: 50,
        font: {
          title: 14,
          content: 12,
        },
      },
      playing: {
        width: 100, // width of video when it is played in vw
        height: 100, // height of video when it is played in vh
        floatingWidth: 40,
        floatingHeight: 40 * 0.56,
      },
      image: {
        width: 55, // width of image in featured image box (videos page)
        height: 55 * 0.5, // height of image in featured image box (videos page)
      },
    },
  },
  stations: {
    grid: {
      cardRow: 72,
      cardWidth: 36,
      image: {
        widthHeight: 24,
        borderRadius: 10,
      },
    },
    slider: {
      slides: 2,
      cardRow: 72, // in vw
      textOffset: 30,
      image: {
        widthHeight: 24, // in vw
        paddingRight: 12,
        borderRadius: 20,
      },
      font: {
        title: 14,
        content: 12,
      },
    },
  },
  podcasts: {
    font: {
      title: 14,
      content: 12,
    },
    slider: {
      slides: 2,
      cardRow: 72, // in vw
      textOffset: 30,
      image: {
        widthHeight: 24, // in vw
        paddingRight: 12,
        borderRadius: 20,
      },
      font: {
        title: 14,
        content: 12,
      },
    },
    grid: {
      imageWidthHeight: 24, // in vw
    },
    list: {
      contentGap: 1, // Space between image card and content,
      shareLikeIconSize: 18,
      textOffset: 100, // read more text offset
      font: {
        mainHeading: 20, // station name and sort heading
        title: 16, // podcast title H3
        date: 6, // podcast date span
      },
      image: {
        borderRadius: 10,
        widthHeight: 30,
      },
    },
  },
  videos: {
    slider: {
      textOffset: 120,
      slides: 1,
      cardRow: 70,
      image: {
        width: 60,
        height: 60 * 0.56,
      },
      font: {
        title: 14,
        content: 12,
      },
    },
    grid: {
      cardRow: 60,
      cardWidth: 60,
      image: {
        width: 60,
        height: 60 * 0.56,
      },
      font: {
        title: 14,
        content: 12,
      },
    },
  },
  presenters: {
    slider: {
      slides: 3,
      cardRow: 63, // in vw
      cardWidth: 18, // in vw
      image: {
        widthHeight: 12,
      },
      font: {
        title: 14,
        content: 12,
      },
    },
  },
  artists: {
    grid: {
      contentGap: 1,
      textOffset: 100,
      image: {
        widthHeight: 20, // in vw
      },
      font: {
        title: 14,
        content: 12,
      },
    },
  },
  publicPages: {
    signin: {
      width: 70,
      font: {
        heading: 18,
      },
    },
    signup: {
      width: 70,
      font: {
        heading: 18,
      },
    },
    HeadingBottomMargin: 5,
  },
  schedule: {
    dayFont: 16,
  },
  userdata: {
    headingFont: 24,
    formWidth: 75, // in vw
  },
  ads: {
    bottomAdsWidth: 60,
    bottomAdsHeight: 60 * 0.15625,
  },
}
