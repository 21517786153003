export const TAB = {
  minWidth: 900,
  page: 900,
  contentWidth: 600,
  websitePadding: 10,
  layout: {
    paddingTop: 15, // in px
    videoPlayerControls: 14, // in px
    playPauseButton: 25, // in px
  },
  header: {
    height: 7, // in vh
    logoHeight: 5, // in vh
    subMenuFont: 0, // font of sub menu also its height in header
    bottomMargin: 0, // Bottom margin of header
  },
  search: {
    height: 28, // in vh
    borderRadius: 3.8,
    font: 14,
    result: {
      font: 16,
      top: 6, // in vh
    },
  },
  player: {
    controls: {
      bottom: {
        height: 75, // in px
        nextPrevHeight: 20, // height of next prev icons in pixel
        playHeight: 40, // in px, height of play button
        volumeHeight: 20, // in px, height of volume button
        titleFont: 20,
        subTitleFont: 14,
        timeFont: 20,
        volumeSliderHeight: 6, // in px
        volumeSliderWidth: 120, // in px
      },
      maximized: {
        height: 30, // in px
        imageHeight: 18, // height of player controls
        imageMarginRight: 24, // right margin for controls on left
        imageMarginLeft: 24, // left margin for controls on right
        volumeSliderWidth: 100, // in px
      },
      floating: {
        height: 30, // in px
        imageHeight: 20, // height of player controls
        closeImageHeight: 30, // height of close button
        imageMarginRight: 24, // right margin for controls on left
        imageMarginLeft: 24, // left margin for controls on right
        titleFont: 18,
      },
    },
  },
  countrySlider: {
    heading: {
      paddingTop: 0,
      paddingBottom: 0,
      font: 28,
      subHeadingFont: 28,
      subTextFont: 14,
    },
    search: {
      width: 400, // in px
      marginBottom: 20,
    },
    flag: {
      large: {
        font: 22, // in px
        width: 250, // in px
        height: 145, // in px
        borderRadius: 10, // in px
      },
      small: {
        flagRow: 740, // in px
        font: 16,
        padding: 30,
        width: 125, // in px,
        height: 72.5, // in px
        selectedWidthIncrease: 0, // should not be more than padding
        selectedHeightIncrease: 0, // should not be more than padding
        borderRadius: 10, // in px
      },
    },
  },
  box: {
    width: 630, // in px
    height: 380,
    borderRadius: 40,
    anchorHeight: 24,
    carouselPaddingRight: 50, // in px
    marginBottom: 20, // gap between heading and image/content section
    iconSize: 20, // like and share button in px
    arrowIconWidth: 30,
    font: {
      mainHeading: 30, // Font size of top heading
    },
    station: {
      featured: {
        contentGap: 15, // in px
        textOffset: 150,
        image: {
          widthHeight: 180,
          borderRadius: 20,
        },
        font: {
          title: 24,
          content: 18,
        },
      },
      details: {
        contentGap: 30,
        textOffset: 150,
        font: {
          title: 24,
          content: 18,
        },
        image: {
          widthHeight: 180, // in px
          borderRadius: 20,
        },
      },
    },
    artist: {
      featured: {
        contentGap: 30,
        textOffset: 150,
        image: {
          widthHeight: 180,
        },
        font: {
          title: 24,
          content: 18,
        },
      },
      details: {
        contentGap: 30,
        textOffset: 150,
        font: {
          title: 24,
          content: 18,
        },
        image: {
          widthHeight: 180, // in px
          borderRadius: 20,
        },
        cover: {
          width: 40, // in vw
          height: 30, // in vw
        },
      },
    },
    podcasts: {
      image: {
        widthHeight: 180,
        borderRadius: 20,
      },
      font: {
        title: 24,
        content: 18,
      },
      contentGap: 20, // gap between image and content
      textSectionWidth: 380, // For smooth sliding experience of text in gray box we need to give fix width
      textOffset: 150, // Read more text characters to allow
    },
    videos: {
      featured: {
        textOffset: 80,
        font: {
          title: 24,
          content: 18,
        },
      },
      playing: {
        width: 660, // width of video when it is played
        height: 660 * 0.56, // height of video when it is played
        floatingWidth: 400,
        floatingHeight: 400 * 0.56,
      },
      image: {
        width: 1000, // width of image in featured image box (videos page)
        height: 380, // height of image in featured image box (videos page)
      },
    },
  },
  contentSection: {
    headingFont: 24,
  },
  stations: {
    grid: {
      paddingBottom: 20, // in px
      cardRow: 660,
      cardWidth: 220,
      image: {
        widthHeight: 160,
        borderRadius: 20,
      },
      font: {
        title: 16,
        content: 14,
      },
    },
    slider: {
      textMarginTop: 10, // in px - margin between text & image
      slides: 3,
      cardRow: 660, // in px
      textOffset: 80,
      image: {
        widthHeight: 160, // in vw
        paddingRight: 60,
        borderRadius: 20,
      },
      font: {
        heading: 32,
        title: 16,
        content: 14,
      },
    },

  },
  podcasts: {
    slider: {
      textMarginTop: 10, // in px - margin between text & image
      slides: 3,
      cardRow: 660, // in px
      textOffset: 80,
      image: {
        widthHeight: 160, // in vw
        paddingRight: 60,
        borderRadius: 20,
      },
      font: {
        heading: 32,
        title: 20,
        content: 16,
      },
    },
    grid: {
      imageWidthHeight: 160, // in px
    },
    list: {
      paddingTop: 40, // in px top/bottom margin of cards
      paddingBottom: 40, // in px top/bottom margin of cards
      contentGap: 15, // Space between image card and content,
      shareLikeIconSize: 20,
      textOffset: 100, // read more text offset
      font: {
        mainHeading: 22, // station name and sort heading
        title: 20, // podcast title H3
        content: 16,
        date: 12, // podcast date span
      },
      image: {
        borderRadius: 10,
        widthHeight: 200,
      },
    },
  },
  videos: {
    slider: {
      textMarginTop: 10, // in px - margin between video & text
      textOffset: 80,
      slides: 2,
      cardRow: 640, // in px
      image: {
        width: 280,
        height: 280 * 0.56,
      },
      font: {
        heading: 32,
        title: 20,
        content: 16,
      },
    },
    grid: {
      paddingBottom: 20, // in px - bottom padding added to each card
      textMarginTop: 10, // in vh - margin between video & text
      cardRow: 640,
      cardWidth: 320,
      image: {
        width: 280,
        height: 280 * 0.56,
      },
      font: {
        title: 18,
        content: 14,
      },
    },
  },
  presenters: {
    slider: {
      slides: 3,
      cardRow: 660, // in px
      cardWidth: 160, // in px
      image: {
        widthHeight: 120,
      },
      font: {
        title: 18,
        content: 14,
      },
    },
  },
  artists: {
    grid: {
      contentGap: 10,
      textOffset: 150,
      image: {
        widthHeight: 160, // in px
      },
      font: {
        title: 24,
        content: 18,
      },
    },
  },
  schedule: {
    dayFont: 24,
  },
  publicPages: {
    signin: {
      width: 400,
      font: {
        heading: 22,
      },
    },
    signup: {
      width: 750,
      formWidth: 600,
      font: {
        heading: 22,
      },
    },
    HeadingBottomMargin: 10,
    welcome: {
      font: {
        heading: 32,
        username: 20,
        email: 16,
        message: 16,
      },
    },
  },
  userdata: {
    formWidth: 450,
    headingFont: 32,
    topRightBtnFont: 24,
    headingPaddingBottom: 20,
  },
  ads: {
    sideAdsWidth: 80,
    bottomAdsWidth: 540,
    bottomAdsHeight: 540 * 0.15625,
  },
  social: {
    width: 210,
  },
}
