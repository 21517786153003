import React from "react"
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client"
import AppContextProvider from "../contexts/AppContext"
import fetch from "isomorphic-fetch"

export const link = createHttpLink({
  fetch,
  uri: `${process.env.STRAPI_SERVER_URL}/graphql`,
})

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
})

export const wrapRootElement = ({ element, props }) => {
  const pageContext = props && props.pageContext ? props.pageContext : {}
  return (
    <ApolloProvider client={client}>
      <AppContextProvider
        defaultValues={{
          ...pageContext,
        }}
      >
        {element}
      </AppContextProvider>
    </ApolloProvider>
  )
}