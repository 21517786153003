import {gql} from "@apollo/client"

export const QUERY_COUNTRY_BY_ID = gql`
  query Country($id: ID!) {
    country(id: $id) {
      id
      title
      image {
        id
        url
      }
      premium
      confirmed
    }
  }
`

export const QUERY_ALL_COUNTRIES_BY_CRITERIA = gql`
  query AllCountries($where: JSON) {
    countries(where: $where) {
      id
      title
      image {
        id
        url
      }
      premium
      confirmed
    }
  }
`

export const QUERY_ALL_COUNTRIES = gql`
  query AllCountries($offset: Int, $max: Int, $order: String) {
    countries(start: $offset, limit: $max, sort: $order) {
      id
      title
      Timezone
      slug
      image {
        id
        url
      }
    }
  }
`

export const QUERY_ALL_COUNTRIES_VIDEOS = gql`
  query AllVideosByCountry($offset: Int, $max: Int, $order: String) {
    countries(
      start: $offset
      limit: $max
      sort: $order
      where: { mediacontents: { contenttype: "video" } }
    ) {
      id
      title
      videos: mediacontents(
        sort: "updatedAt:desc"
        where: { contenttype: "video" }
      ) {
        id
        title
        uri
        contenttype
        image {
          id
          url
        }
        artist {
          id
          title
        }
        presenter {
          id
          title
        }
        station {
          id
          title
        }
        premium
        updatedAt
      }
    }
  }
`
