import styled from "styled-components"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"

import {
  PROP_FONT_PRIMARY,
  PROP_FONT_SECONDARY,
} from "../../constants/props"

import React from "react"
import ReadMorePara from "../ReadMorePara"


// FEATURED VIDEO COMPONENTS

export const VideoFeaturedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding-right: ${MOBILE.box.carouselPaddingRight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    padding-right: ${LANDSCAPE.box.carouselPaddingRight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    padding-right: ${TAB.box.carouselPaddingRight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    padding-right: ${MEDIUM.box.carouselPaddingRight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    padding-right: ${LARGE.box.carouselPaddingRight}px;
  }
`

export const VideoFeaturedImageWrapper = styled.div`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.box.videos.image.width}vw;
    height: ${MOBILE.box.videos.image.height}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.box.videos.image.width}vw;
    height: ${LANDSCAPE.box.videos.image.height}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.box.videos.image.width}px;
    height: ${TAB.box.videos.image.height}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.box.videos.image.width}px;
    height: ${MEDIUM.box.videos.image.height}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.box.videos.image.width}px;
    height: ${LARGE.box.videos.image.height}px;
  }
` 
export const VideoFeaturedContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding-top: ${props =>
      props.videoPlaying ? MOBILE.box.videos.playing.height + "vw" : "0px"};
    width: ${MOBILE.contentWidth}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    padding-top: ${props =>
      props.videoPlaying ? LANDSCAPE.box.videos.playing.height + "vh" : "0px"};
    width: ${LANDSCAPE.box.videos.playing.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    padding-top: ${props =>
      props.videoPlaying ? TAB.box.videos.playing.height + "px" : "0px"};
    width: ${TAB.contentWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    padding-top: ${props =>
      props.videoPlaying ? MEDIUM.box.videos.playing.height + "px" : "0px"};
    width: ${MEDIUM.contentWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    padding-top: ${props =>
      props.videoPlaying ? LARGE.box.videos.playing.height + "px" : "0px"};
    width: ${LARGE.contentWidth}px;
  }
`

export const VideoFeaturedBottomTextSection = styled.div`
  display: flex;
  flex: 1;
  margin-top: 10px;
  justify-content: space-between;
`

export const VideoFeaturedColumnText = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: ${MOBILE.box.videos.featured.font.content}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.videos.featured.font.content}px;
  }
  flex-wrap: wrap;
  text-overflow: ellipsis;
`


export const VideoFeaturedTitle = styled.h3`
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  letter-spacing: 1.5px;
  font-size: ${MOBILE.box.videos.featured.font.title}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.videos.featured.font.title}px;
  }
`
// SLIDER VIDEO COMPONENTS

export const VideoFeaturedBox = styled.div`
  background: ${props => (props.videoPlaying ? "none" : "#464a52")};
  width: 100%;
  margin: 0px auto;
  padding: 5px 0px 25px 0px;
  border-radius: 80px;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  overflow: hidden;
  @media (max-width: ${MOBILE.maxWidth}px) {
    border-radius: ${MOBILE.box.borderRadius}px;
    width: ${MOBILE.box.width}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    border-radius: ${LANDSCAPE.box.borderRadius}px;
    width: ${LANDSCAPE.box.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    padding: 30px 0px;
    width: ${TAB.box.width}px;
    border-radius: ${TAB.box.borderRadius}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.box.width}px;
    border-radius: ${MEDIUM.box.borderRadius}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.box.width}px;
    border-radius: ${LARGE.box.borderRadius}px;
  }
`
export const VideoSliderImageWrapper = styled.div`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.videos.slider.image.width}vw;
    height: ${MOBILE.videos.slider.image.height}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.videos.slider.image.width}vw;
    height: ${LANDSCAPE.videos.slider.image.height}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.videos.slider.image.width}px;
    height: ${TAB.videos.slider.image.height}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.videos.slider.image.width}px;
    height: ${MEDIUM.videos.slider.image.height}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.videos.slider.image.width}px;
    height: ${LARGE.videos.slider.image.height}px;
  }
`

export const VideoCardInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.videos.slider.image.width}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.videos.slider.image.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 20px;
    width: ${TAB.videos.slider.image.width}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.videos.slider.image.width}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.videos.slider.image.width}px;
  }
`


/* border: 1px solid black; */
export const VideoInfoLabel = styled.span`
flex-wrap: wrap;
text-overflow: ellipsis;
font-family: ${PROP_FONT_SECONDARY};
  margin: 0;
  &::before {
    content: "${props => props.tag}";
    font-family: ${PROP_FONT_PRIMARY};
    font-weight: bold;
  }
`
export const VideoTitle = styled.h3`
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  letter-spacing: 1.5px;
  font-size: ${MOBILE.box.videos.featured.font.title}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.videos.featured.font.title}px;
  }
`
export const VideoIconsSection = styled.div`
  display: flex;
`
export const VideoIcon = styled.img`
  width: 25px;
  margin-left: 15px;
  cursor: pointer;
  margin-top: 10px;
`
export const VideoViews = styled.div`
  & span {
    color: #fff;
    letter-spacing: 1.5px;
    font-size: ${MOBILE.box.videos.featured.font.title}px;
    @media (min-width: ${TAB.minWidth}px) {
      font-size: ${TAB.box.videos.featured.font.title}px;
    }
  }
  & span:first-child {
    font-family: ${PROP_FONT_PRIMARY};
  }
  & span:last-child {
    font-family: ${PROP_FONT_SECONDARY};
  }
`

export const VideoSliderWrapper = styled.li`
  display: flex;
  flex-direction: column;
`
export const VideoSliderInfoWrapper = styled.div`
  margin-top: ${MOBILE.videos.slider.textMarginTop}vh;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.videos.slider.image.width}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.videos.slider.image.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: ${TAB.videos.slider.textMarginTop}px;
    width: ${TAB.videos.slider.image.width}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.videos.slider.image.width}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.videos.slider.image.width}px;
  }
`
export const VideoSliderHeading = styled.div`
  font-family: ${PROP_FONT_PRIMARY};
  color: #fff;
  margin-top: 0;
  margin-bottom: 15px;
  ::selection {
    color: none;
    background: none;
  }
  font-size: ${MOBILE.videos.slider.font.heading}px;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    &:hover {
      text-decoration: none;
      transform: scale(${LANDSCAPE.linkHover.scale}) translateX(65px);
      transition: all ${LANDSCAPE.linkHover.transition}s;
      color: ${LANDSCAPE.linkHover.color};
    }
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.videos.slider.font.heading}px;
  }
`

export const VideoSliderTitle = styled.h3`
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  letter-spacing: 1.5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 5px;
  font-size: ${MOBILE.videos.slider.font.title}px;
  & span {
    font-size: ${MOBILE.videos.slider.font.title}px;
    float: right;
    padding-top: 8px;
  }

  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.videos.slider.font.title}px;
    & span {
      font-size: ${TAB.videos.slider.font.title}px;
    }
  }
`
export const VideoSliderSingleLineText = styled.div`
  display: block;
  list-style: none;
  color: #fff;
  font-family: ${PROP_FONT_SECONDARY};
  font-style: italic;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${MOBILE.videos.slider.font.content}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.videos.slider.font.content}px;
  }
`

export const VideoSliderReadMore = styled.div`
  color: #fff;
  font-family: ${PROP_FONT_SECONDARY};
  font-style: italic;
  overflow: hidden;
  font-size: ${MOBILE.videos.slider.font.content}px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    margin-top: 5px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.videos.slider.font.content}px;
  }
  & b {
    letter-spacing: 1.5px;
  }
`
export const VideoSliderReadMoreSection = ({ string, offset }) => (
  <VideoSliderReadMore>
    <ReadMorePara string={string} offset={offset} />
  </VideoSliderReadMore>
)

// VIDEO GRID COMPONENTS

export const VideoGridAllWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-start; 
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.videos.grid.cardRow}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.videos.grid.cardRow}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.videos.grid.cardRow}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.videos.grid.cardRow}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.videos.grid.cardRow}px;
  }
`
 
export const VideoGridWrapper = styled.li`
  display: flex;
  flex-direction: column;
  
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding-bottom: ${MOBILE.videos.grid.paddingBottom}vw;
    width: ${MOBILE.videos.grid.cardWidth}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.videos.grid.cardWidth}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.videos.grid.cardWidth}px;
    padding-bottom: ${TAB.videos.grid.paddingBottom}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.videos.grid.cardWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.videos.grid.cardWidth}px;
  }
`
export const VideoGridImageWrapper = styled.div`
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.videos.grid.image.width}vw;
    height: ${MOBILE.videos.grid.image.height}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.videos.grid.image.width}vw;
    height: ${LANDSCAPE.videos.grid.image.height}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.videos.grid.image.width}px;
    height: ${TAB.videos.grid.image.height}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.videos.grid.image.width}px;
    height: ${MEDIUM.videos.grid.image.height}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.videos.grid.image.width}px;
    height: ${LARGE.videos.grid.image.height}px;
  }
`
export const VideoGridInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.videos.grid.image.width}vw;
    margin-top: ${MOBILE.videos.grid.textMarginTop}vh;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.videos.grid.image.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.videos.grid.image.width}px;
    margin-top: ${MOBILE.videos.grid.textMarginTop}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.videos.grid.image.width}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.videos.grid.image.width}px;
  }
`
export const VideoGridTitle = styled.h3`
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  letter-spacing: 1.5px;
  font-size: ${MOBILE.videos.grid.font.title}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.videos.grid.font.title}px;
  }
`
export const VideoGridSingleLineText = styled.div`
  display: block;
  list-style: none;
  color: #fff;
  font-family: ${PROP_FONT_SECONDARY};
  font-style: italic;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.videos.grid.image.width}vw;
    font-size: ${MOBILE.videos.grid.font.content}px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.videos.grid.image.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.videos.grid.image.width}px;
    font-size: ${TAB.videos.grid.font.content}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.videos.grid.image.width}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.videos.grid.image.width}px;
  }
`
