export {MOBILE} from './mobile'
export {LANDSCAPE} from './landscape'
export {TAB} from './tab'
export {MEDIUM} from './desktop'
export {LARGE} from './largedesktop'

export const DEVICE_MOBILE = "mobile"
export const DEVICE_LANDSCAPE = "landscape"
export const DEVICE_TAB = "tab"
export const DEVICE_MEDIUM = "medium"
export const DEVICE_LARGE = "large"
