// This will contain all the style components that are designed to be shared at multiple places
import styled from "styled-components"
import {
  PROP_FONT_PRIMARY,
  PROP_FONT_SECONDARY,
} from "../../constants/props"
import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"


// Contains left/right ads and center content
export const ContentParent = styled.div`
  display: flex;
  margin: 0 auto;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.box.width}vw;
  }
  @media (min-width: ${LANDSCAPE.maxWidth}px) {
    width: ${LANDSCAPE.box.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 30px;
    width: ${TAB.page}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.page}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.page}px;
  }
`

// Represents center content
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: ${MOBILE.player.controls.bottom.height}vw;
  @media (max-width: ${MOBILE.maxWidth}px) {
    padding-top: ${MOBILE.layout.paddingTop}vh;
  }
  
  @media (min-width: ${LANDSCAPE.minWidth}px and max-width: ${TAB.minWidth}) {
    padding-top: ${LANDSCAPE.layout.paddingTop}vh;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-bottom: ${TAB.player.controls.bottom.height}px;
  }
  
`

export const CenterContentSection = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.box.width}vw;
    margin: 0 auto;
  }
  @media (min-width: ${LANDSCAPE.maxWidth}px) {
    width: ${LANDSCAPE.box.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.box.width}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.box.width}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.box.width}px;
  }
`
export const ContentSection = styled.div`
  display: flex;
  margin: 0 auto;
  margin-top: 10px;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.page}vw;
  }
  @media (min-width: ${LANDSCAPE.maxWidth}px) {
    width: ${LANDSCAPE.page}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 30px;
    width: ${TAB.page}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.page}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.page}px;
  }
`

export const CenterContentWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.contentWidth}vw;
    margin: 0 auto;
  }
  @media (min-width: ${LANDSCAPE.maxWidth}px) {
    width: ${LANDSCAPE.contentWidth}vwpx;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.contentWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.contentWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.contentWidth}px;
  }
`
export const CenterContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 400px;
`
export const NoDataMessage = styled.h3`
  display: flex;
  align-items: center;
  text-align: center;
  flex: 2;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: 16px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    margin-bottom: 400px;
    font-size: 18px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 20px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: 22px;
  }
`
// ALL CARDS RELATED COMPONENT (ALL STATIONS, ALL VIDEOS ETC)
export const AllCardsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.contentWidth}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.contentWidth}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.contentWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.contentWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.contentWidth}px;
  }
`

// HEADING + SORT OPTIONS FOR ALL CARDS SECTION (ALL STATIONS, ALL VIDEOS ETC)
export const AllCardsTopSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-family: ${PROP_FONT_PRIMARY};
  font-size: ${MOBILE.contentSection.headingFont}px;
  letter-spacing: 1.2px;
  @media (min-width: ${TAB.minWidth}px) {
    margin-bottom: 15px;
    font-size: ${TAB.contentSection.headingFont}px;
  }
`
export const AllCardsHeading = styled.span`
  color: #fff;
  float: left;
  text-transform: uppercase;
`
export const SortSpan = styled.span`
  color: #fff;
  float: right;
`
export const SortSelect = styled.select`
  color: #fff;
  background: none;
  border: none;
  outline: none;
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0px 5px;
`
export const SortOption = styled.option`
  color: #fff;
  background: #064072;
  font-size: 20px;
  outline: none;
  font-family: ${PROP_FONT_PRIMARY};
`
// WRAPPER AND OTHER COMPONENTS FOR ALL CARDS SECTION (ALL STATIONS, ALL VIDEOS ETC)
export const AllCardsWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
`
export const SingleCardWrapper = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  padding-top: 10px;
  @media (min-width: ${LARGE.minWidth}px) {
    padding-right: 1px;
  }
`


export const PodcastLabelOnTopSection = styled.span`
  flex-wrap: wrap;
  font-family: ${PROP_FONT_SECONDARY};
  margin: 0;
  font-size: ${MOBILE.podcasts.slider.font.content}px;
  &::before {
    content: "${props => props.tag}";
    font-size: ${MOBILE.podcasts.slider.font.content}px;
    font-family: ${PROP_FONT_PRIMARY};
    font-weight: bold;
    letter-spacing: 1.5px;
    
  }
  margin-top: 15px;
  
  @media (min-width: ${TAB.minWidth}px) {
    & span {
      font-size: ${TAB.podcasts.slider.font.content}px;
    }
    &::before {
      font-size: ${TAB.podcasts.slider.font.content}px;
    }
  }
`

// DATA MANAGEMENT RELATED
export const DataTopHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${MOBILE.userdata.headingPaddingBottom}px;
  @media (min-width: ${TAB.minWidth}px) {
    padding-bottom: ${TAB.userdata.headingPaddingBottom}px;
  }
`
export const DataTopHeading = styled.h3`
  color: #fff;
  font-family: ${PROP_FONT_PRIMARY};
  font-size: ${MOBILE.userdata.headingFont}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.userdata.headingFont}px;
  }
`

export const TopRightButton = styled.div`
  color: black;
  & button {
    padding: 3px 20px;
    border-radius: 3px;
    outline: 0;
    font-family: ${PROP_FONT_PRIMARY};
    font-size: ${MOBILE.userdata.topRightBtnFont}px;
    @media (min-width: ${TAB.minWidth}px) {
      font-size: ${TAB.userdata.topRightBtnFont}px;
    }
  }

  & button:focus {
    outline: 0;
  }
`

export const UserDataHeading = styled.div`
  font-family: ${PROP_FONT_PRIMARY};
  color: #fff;
  margin-top: 0;
  margin-bottom: 15px;
  ::selection {
    color: none;
    background: none;
  }
  font-size: ${MOBILE.podcasts.slider.font.heading}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.podcasts.slider.font.heading}px;
  }
`

