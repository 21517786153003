import gql from "graphql-tag"

export const QUERY_ALL_USER_LIKES = gql`
  query AllUserLikes($userID: ID!) {
    userLikes(where: { user: $userID }) {
      id
      type
      station {
        id
        title
        slug
      }
      artist {
        id
        title
      }
      mediacontent {
        id
        title
        about
        uri
        premium
        image {
          id
          url
        }
        station {
          id
          title
        }
        artist {
          id
          title
        }
      }
    }
  }
`
export const MUTATION_ADD_LIKE = gql`
  mutation AddLike($input: createUserLikeInput) {
    createUserLike(input: $input) {
      userLike {
        id
        station {
          id
          title
        }
        artist {
          id
          title
        }
        mediacontent {
          id
          title
          about
          uri
          premium
          image {
            id
            url
          }
          station {
            id
            title
          }
          artist {
            id
            title
          }
        }
      }
    }
  }
`

export const MUTATION_DELETE_LIKE = gql`
  mutation DeleteLike($input: deleteUserLikeInput) {
    deleteUserLike(input: $input) {
      userLike {
        id
      }
    }
  }
`

export const MUTATION_ADD_USER_DATA = gql`
  mutation AddUserData($input: createUserDatumInput) {
    createUserDatum(input: $input) {
      userDatum {
        id
        type
        lastPlayed
        lastIncremented
        playedCount
        artist {
          id
          title
        }
        station {
          id
          title
        }
        mediacontent {
          id
          title
        }
      }
    }
  }
`

export const MUTATION_UPDATE_USER_DATA = gql`
  mutation UpdateUserData($input: updateUserDatumInput) {
    updateUserDatum(input: $input) {
      userDatum {
        id
        type
        lastPlayed
        lastIncremented
        playedCount
        artist {
          id
          title
        }
        station {
          id
          title
        }
        mediacontent {
          id
          title
        }
      }
    }
  }
`

export const QUERY_ALL_USER_DATA = gql`
  query AllUserData($userID: ID!) {
    userData(where: { user: $userID }) {
      id
      type
      lastPlayed
      lastIncremented
      playedCount
      artist {
        id
        title
      }
      station {
        id
        title
      }
      mediacontent {
        id
        title
      }
    }
  }
`

export const QUERY_USER_DATA_COUNT = gql`
  query UserDataConnection($sort: String, $limit: Int, $start: Int, $where: JSON) {
    userDataConnection(sort: $sort, where: $where, limit: $limit, start: $start) {
      aggregate {
        count
      }
    }
  }
`

export const QUERY_USER_DATA = gql`
  query AllUserData($sort: String, $limit: Int, $start: Int, $where: JSON) {
    userData(sort: $sort, where: $where, limit: $limit, start: $start) {
      id
      type
      lastPlayed
      lastIncremented
      playedCount
      artist {
        id
        title
      }
      station {
        id
        title
        available
        country {
          id
          title
        }
        streamuri
        image {
          id
          url
        }
        presenters: presenters {
          id
          title
          image {
            id
            url
          }
        }
      }
      mediacontent {
        id
        title
        about
        uri
        premium
        viewed
        image {
          id
          url
        }
        station {
          id
          title
        }
        artist {
          id
          title
        }
      }
    }
  }
`

export const QUERY_PAGE_DATA = gql`
  query AllPages($sort: String, $limit: Int, $start: Int, $where: JSON) {
    pages(sort: $sort, where: $where, limit: $limit, start: $start) {
      id
      identifier
    }
  }
`
